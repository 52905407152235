export const getCurrencySymbolFromCode = (code: string): string =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: code,
    currencyDisplay: 'narrowSymbol',
  })
    .formatToParts(1)
    .find((x) => x.type === 'currency').value;

export const getCurrencyNameFromCode = (code: string): string =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: code,
    currencyDisplay: 'name',
  })
    .formatToParts(1)
    .find((x) => x.type === 'currency').value;

export const formatCurrency = (
  value: number,
  currencyCode: string = 'GBP',
  options: object = {},
  locale: string = undefined, // Undefined means use the browser locale
): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    ...options,
  });

  return formatter.format(value);
};
