import {
  Employee,
  LeaveAllowanceChunk,
  LeaveAllowanceChunkAllowanceUnitEnum,
  LeavePeriod,
  LeaveRequest,
  LeaveRequestStatusEnum,
} from '@/../api/v1';
import i18n from '@/i18n';
import { MINUTES_IN_HOUR } from '@/lib/date-time/constants';
import { PlainDate } from '@/lib/date-time/PlainDate';
import {
  LeaveEntitlementUnitEnum,
  LeaveEntitlementUnitType,
} from '@/lib/leave/leaveTypeTypes';
import { endDateToDateTime, startDateToDateTime } from '@/util/dateFunctions';
import { round } from '@/util/mathFunctions';
import { keyOfFirstTruthyValue } from '@/util/objectFunctions';
import { Permission } from '@/util/permissionFunctions';
import spacetime from 'spacetime';
import store from '../../store';

export const leaveStartDate = (leave: LeaveRequest, timezone: string) => {
  return startDateToDateTime(leave.startDate, leave.startsMidday, timezone);
};
export const leaveEndDate = (leave: LeaveRequest, timezone: string) => {
  return endDateToDateTime(leave.endDate, leave.endsMidday, timezone);
};

export const isApproved = ({ status }: LeaveRequest) =>
  status === LeaveRequestStatusEnum.Approved;

export const isPendingApproval = ({ status }: LeaveRequest) =>
  status === LeaveRequestStatusEnum.PendingApproval;

export const isPendingCancellation = ({ status }: LeaveRequest) =>
  status === LeaveRequestStatusEnum.PendingCancellation;

export const isPending = (request: LeaveRequest) =>
  isPendingApproval(request) || isPendingCancellation(request);

export const isDenied = ({ status }: LeaveRequest) =>
  status === LeaveRequestStatusEnum.Denied;

export const isApprovable = (request: LeaveRequest) =>
  isPendingApproval(request) || isPendingCancellation(request);

/**
 * Work out whether a leave request can be reviewed (approved/denied/cancelled).
 * This mirrors a corresponding function on the backend.
 *
 * See the flow chart on https://shiftie.atlassian.net/browse/SHIF-1739 for an overview of this logic
 */
export const canReviewLeaveFor = (leaveTaker: Employee): boolean => {
  // Current employee
  const reviewer = store.getters.loggedInEmployee;

  // Everyone who has the 'manage leave' permission can review leave
  // if they are reviewing for someone else OR they don't have an approver.
  if (store.state.permissions.includes(Permission.ManageLeave)) {
    return reviewer.id !== leaveTaker.id || !leaveTaker.leaveApproverId;
  }

  // Get the default leave approver from the store
  const { defaultLeaveApproverId = null } = store.state.settings.leaveSettings;

  // Find out who the approver is , if any, for the leave-taker
  const approverId = leaveTaker.leaveApproverId || defaultLeaveApproverId;

  // The leave-taker's approver can always review their leave
  if (reviewer.id === approverId) {
    return true;
  }

  // Leave-takers without an approver mean they effectively can manage their own leave
  if (!approverId && reviewer.id === leaveTaker.id) {
    return true;
  }

  // By default, don't allow
  return false;
};

export const canBeCancelled = (leaveRequest: LeaveRequest): boolean =>
  [
    LeaveRequestStatusEnum.Approved,
    LeaveRequestStatusEnum.PendingApproval,
  ].includes(leaveRequest.status);

export const formatLeaveTime = (
  leave: LeaveRequest,
  timezone: string,
  showDate: boolean,
): string => {
  const { endsMidday } = leave;

  const startDate = leaveStartDate(leave, timezone);
  const endDate = leaveEndDate(leave, timezone);

  const spaceStart = spacetime(startDate, timezone);
  let spaceEnd = spacetime(endDate, timezone);

  if (spaceStart.diff(spaceEnd, 'day') < 1) {
    return (
      endsMidday ? i18n.t('time.morning') : i18n.t('time.afternoon')
    ).toString();
  }
  if (spaceStart.diff(spaceEnd, 'day') === 1 || !showDate)
    return i18n.t('label.fullDay').toString();

  if (!endsMidday) spaceEnd = spaceEnd.subtract(1, 'day');
  const timeString = i18n.d(startDate, 'dayMonth');
  if (spaceStart.isSame(spaceEnd, 'day')) return timeString;

  return `${timeString} - ${i18n.d(spaceEnd.toNativeDate(), 'dayMonth')}`;
};

export const halfDayText = (request: LeaveRequest): string => {
  // If we ever change our leave requests to allow multi-day leave that can
  // start or end at midday, then this would need making more sophisticated.
  if (request.endsMidday) {
    return i18n.t('time.morning').toString();
  }
  if (request.startsMidday) {
    return i18n.t('time.afternoon').toString();
  }
  return '';
};

export const leaveDayText = (
  leaveRequest: LeaveRequest,
  start: Date,
  end: Date,
  timezone: string,
): string => {
  const presentationalEndDate = spacetime(end, timezone)
    .subtract(1, 'day')
    .toNativeDate();

  const leaveStart = leaveStartDate(leaveRequest, timezone);
  const leaveEnd = leaveEndDate(leaveRequest, timezone);
  const isStartDay = spacetime(start, timezone).isSame(
    spacetime(leaveStart, timezone),
    'day',
  );
  const isEndDay = spacetime(presentationalEndDate, timezone).isSame(
    spacetime(leaveEnd, timezone),
    'day',
  );

  return (keyOfFirstTruthyValue({
    [i18n.tc('time.afternoon')]: isStartDay && leaveRequest.startsMidday,
    [i18n.tc('time.morning')]:
      (isStartDay || isEndDay) && leaveRequest.endsMidday,
  }) || i18n.tc('label.fullDay')) as string;
};

export const presentLeaveDuration = (
  amount: number,
  leaveEntitlementUnit: LeaveEntitlementUnitType,
): string => {
  return LeaveEntitlementUnitEnum.Hours === leaveEntitlementUnit
    ? i18n.tc('unitWithAmount.hour', amount === 1 ? 1 : 2, {
        n: round(amount, 2),
      })
    : i18n.tc('unitWithAmount.day', amount === 1 ? 1 : 2, {
        n: round(amount, 2),
      });
};

export const presentLeaveUnit = (
  amount: number,
  leaveEntitlementUnit: LeaveEntitlementUnitType,
): string => {
  return leaveEntitlementUnit === LeaveEntitlementUnitEnum.Hours
    ? i18n.tc('unit.hour', amount === 1 ? 1 : 2)
    : i18n.tc('unit.day', amount === 1 ? 1 : 2);
};

export const totalLeaveAllowanceUsed = (
  leaveAllowanceChunks: LeaveAllowanceChunk[],
) => {
  if (!leaveAllowanceChunks.length) {
    return 0;
  }
  return round(
    leaveAllowanceChunks.reduce(
      (acc, curr) => acc + (curr.allowanceAffected ? curr.allowanceUsed : 0),
      0,
    ),
    2,
  );
};

export const leaveAllowanceUsedForPeriod = (
  leaveAllowanceChunks: LeaveAllowanceChunk[],
  leavePeriod: LeavePeriod | undefined,
) => {
  if (!leaveAllowanceChunks.length || !leavePeriod) {
    return 0;
  }
  const chunks = leaveAllowanceChunks.filter(
    (c) => c.date >= leavePeriod.startDate && c.date <= leavePeriod.endDate,
  );
  return totalLeaveAllowanceUsed(chunks);
};

export const getLeaveRequestDayPaidMinutes = (
  leave: LeaveRequest,
  date: Date,
  timezone: string,
  // TODO Remove this parameter on leaveHoursUsedPerDay is available on chunk object
  employee: Employee,
): number => {
  if (!leave.paid) {
    return 0;
  }

  const chunk = leave.leaveAllowanceChunks.find(
    (chunk) =>
      !PlainDate.compare(chunk.date, PlainDate.fromDate(date, timezone)),
  );

  if (!chunk || !chunk.paid) {
    return 0;
  }

  if (chunk.allowanceUnit === LeaveAllowanceChunkAllowanceUnitEnum.Hours) {
    return chunk.allowanceUsed * MINUTES_IN_HOUR;
  }
  if (chunk.allowanceUnit === LeaveAllowanceChunkAllowanceUnitEnum.Days) {
    return (
      employee.leaveHoursUsedPerDay * chunk.allowanceUsed * MINUTES_IN_HOUR
    );
  }

  return 0;
};
