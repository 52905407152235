
import store from '@/store';
import { hideFixedElement, showFixedElement } from '@/util/displayFunctions';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

export default {
  name: 'TextInput',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Object],
      default: '',
    },

    labelText: {
      type: [String, Number, TranslateResult],
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    placeholderText: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    isValid: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [Array, String],
      default: null,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    // Marks this input as sensitive so hides data when impersonating and forces readonly
    sensitive: {
      type: Boolean,
      default: false,
    },

    // Used for FormDatePicker to prevent typing in field
    typeable: {
      type: Boolean,
      default: true,
    },

    height: {
      type: [Number, String],
      default: 56,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
    },

    type: {
      type: String,
      default: 'text',
    },

    /**
     * Use "numeric" for integer fields
     * @see https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
     * @see https://design-system.service.gov.uk/components/text-input/#numbers
     */
    inputmode: {
      type: String,
      default: 'text',
    },
  },

  data() {
    return {
      internalValue: this.value,
      timerId: null,
    };
  },

  computed: {
    isImpersonationSession: (): boolean => store.state.isImpersonationSession,
    isEnhancedImpersonationSession: (): boolean =>
      store.state.isEnhancedImpersonationSession,

    getListeners() {
      const { ...listeners } = this.$listeners;
      delete listeners.input;
      delete listeners.blur;
      delete listeners.focus;
      return listeners;
    },

    inputValue: {
      get() {
        return this.value;
      },
    },

    isSecured(): boolean {
      return (
        this.sensitive &&
        this.isImpersonationSession &&
        !this.isEnhancedImpersonationSession
      );
    },
  },

  methods: {
    hideFixedElement,

    handleInput(event) {
      clearTimeout(this.timerId);

      // delay new call
      this.timerId = setTimeout(() => {
        showFixedElement('#fixed-button');
      }, 1000);
      this.$emit('input', event);
      this.internalValue = event;
    },

    blur() {
      this.$emit('blur', this.internalValue);
      showFixedElement('#fixed-button');
    },
  },
};
