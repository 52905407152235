
import Button from '@/components/buttons/Button.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import Pill from '@/components/interface/Pill.vue';
import { Icon } from '@/lib/enum/Icon';
import { formatCurrency } from '@/util/currencyFunctions';
import { PropType } from 'vue';
import {
  BillingPlan,
  BillingPlanFeaturesEnum,
  BillingPlanIntervalEnum,
} from '../../../../api/v1';

export default {
  name: 'PackageCardV2',
  components: {
    BubbleIcon,
    Pill,
    Button,
  },
  props: {
    package: {
      type: Object as PropType<BillingPlan>,
      required: true,
    },
    isCurrentPackage: {
      type: Boolean,
      default: false,
    },
    canTrialPlan: {
      type: Boolean,
      default: false,
    },
    canChoosePlan: {
      type: Boolean,
      default: false,
    },
    showChoosePlan: {
      type: Boolean,
      default: false,
    },
    mostPopular: {
      type: Boolean,
      default: false,
    },
    collapseFeatures: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BillingPlanIntervalEnum,
      Icon,
      showFeatures: !this.collapseFeatures,
      planFeaturesOrder: {
        1: BillingPlanFeaturesEnum.Scheduler,
        2: BillingPlanFeaturesEnum.Availability,
        3: BillingPlanFeaturesEnum.HrManagement,
        4: BillingPlanFeaturesEnum.Leave,
        5: BillingPlanFeaturesEnum.Documents,
        6: BillingPlanFeaturesEnum.ClockInOut,
        7: BillingPlanFeaturesEnum.MultiVenue,
        8: 'Unlimited Support',
      },
    };
  },
  computed: {
    planFeaturesList() {
      return Object.values(this.planFeaturesOrder).reduce(
        (acc, featureName) => {
          acc.push({
            checked:
              this.package.features.includes(featureName) ||
              featureName === 'Unlimited Support',
            name: this.$t(`enum.planFeatures.${featureName}`),
          });
          return acc;
        },
        [],
      );
    },
  },
  methods: { formatCurrency },
};
