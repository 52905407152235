/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateScheduleSettingsData
 */
export interface UpdateScheduleSettingsData {
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduleSettingsData
     */
    defaultBreakLength?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    enableBreaksWhileClockedIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    deductAllocatedBreaksFromWorkingTime?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleSettingsData
     */
    clockInUnscheduledShifts?: UpdateScheduleSettingsDataClockInUnscheduledShiftsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeeSwitchAndRejectShifts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeesMustAcknowledgeShifts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeeShiftChangeNeedsApproval?: boolean;
    /**
     * Allows employees to view all a companies shifts.
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeesCanViewAllShifts?: boolean;
    /**
     * Prevents employees from viewing shifts from different locations they aren't assigned to.
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeesCanOnlyViewShiftsForTheirLocations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeesCanClaimOpenShiftsOnNonWorkingDays?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    employeesCanViewShiftTags?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    shiftSwappingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    shiftSwapsRequireApproval?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduleSettingsData
     */
    swapCutOffHoursBeforeShift?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateScheduleSettingsData
     */
    enableOpenShifts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleSettingsData
     */
    paidBreakName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleSettingsData
     */
    unpaidBreakName?: string;
}

/**
* @export
* @enum {string}
*/
export enum UpdateScheduleSettingsDataClockInUnscheduledShiftsEnum {
    Always = 'Always',
    IfNoShiftsToday = 'If No Shifts Today',
    Never = 'Never'
}


/**
 * Check if a given object implements the UpdateScheduleSettingsData interface.
 */
export function instanceOfUpdateScheduleSettingsData(value: object): boolean {
    return true;
}

export function UpdateScheduleSettingsDataFromJSON(json: any): UpdateScheduleSettingsData {
    return UpdateScheduleSettingsDataFromJSONTyped(json, false);
}

export function UpdateScheduleSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleSettingsData {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultBreakLength': json['defaultBreakLength'] == null ? undefined : json['defaultBreakLength'],
        'enableBreaksWhileClockedIn': json['enableBreaksWhileClockedIn'] == null ? undefined : json['enableBreaksWhileClockedIn'],
        'deductAllocatedBreaksFromWorkingTime': json['deductAllocatedBreaksFromWorkingTime'] == null ? undefined : json['deductAllocatedBreaksFromWorkingTime'],
        'clockInUnscheduledShifts': json['clockInUnscheduledShifts'] == null ? undefined : json['clockInUnscheduledShifts'],
        'employeeSwitchAndRejectShifts': json['employeeSwitchAndRejectShifts'] == null ? undefined : json['employeeSwitchAndRejectShifts'],
        'employeesMustAcknowledgeShifts': json['employeesMustAcknowledgeShifts'] == null ? undefined : json['employeesMustAcknowledgeShifts'],
        'employeeShiftChangeNeedsApproval': json['employeeShiftChangeNeedsApproval'] == null ? undefined : json['employeeShiftChangeNeedsApproval'],
        'employeesCanViewAllShifts': json['employeesCanViewAllShifts'] == null ? undefined : json['employeesCanViewAllShifts'],
        'employeesCanOnlyViewShiftsForTheirLocations': json['employeesCanOnlyViewShiftsForTheirLocations'] == null ? undefined : json['employeesCanOnlyViewShiftsForTheirLocations'],
        'employeesCanClaimOpenShiftsOnNonWorkingDays': json['employeesCanClaimOpenShiftsOnNonWorkingDays'] == null ? undefined : json['employeesCanClaimOpenShiftsOnNonWorkingDays'],
        'employeesCanViewShiftTags': json['employeesCanViewShiftTags'] == null ? undefined : json['employeesCanViewShiftTags'],
        'shiftSwappingEnabled': json['shiftSwappingEnabled'] == null ? undefined : json['shiftSwappingEnabled'],
        'shiftSwapsRequireApproval': json['shiftSwapsRequireApproval'] == null ? undefined : json['shiftSwapsRequireApproval'],
        'swapCutOffHoursBeforeShift': json['swapCutOffHoursBeforeShift'] == null ? undefined : json['swapCutOffHoursBeforeShift'],
        'enableOpenShifts': json['enableOpenShifts'] == null ? undefined : json['enableOpenShifts'],
        'paidBreakName': json['paidBreakName'] == null ? undefined : json['paidBreakName'],
        'unpaidBreakName': json['unpaidBreakName'] == null ? undefined : json['unpaidBreakName'],
    };
}

export function UpdateScheduleSettingsDataToJSON(value?: UpdateScheduleSettingsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'defaultBreakLength': value['defaultBreakLength'],
        'enableBreaksWhileClockedIn': value['enableBreaksWhileClockedIn'],
        'deductAllocatedBreaksFromWorkingTime': value['deductAllocatedBreaksFromWorkingTime'],
        'clockInUnscheduledShifts': value['clockInUnscheduledShifts'],
        'employeeSwitchAndRejectShifts': value['employeeSwitchAndRejectShifts'],
        'employeesMustAcknowledgeShifts': value['employeesMustAcknowledgeShifts'],
        'employeeShiftChangeNeedsApproval': value['employeeShiftChangeNeedsApproval'],
        'employeesCanViewAllShifts': value['employeesCanViewAllShifts'],
        'employeesCanOnlyViewShiftsForTheirLocations': value['employeesCanOnlyViewShiftsForTheirLocations'],
        'employeesCanClaimOpenShiftsOnNonWorkingDays': value['employeesCanClaimOpenShiftsOnNonWorkingDays'],
        'employeesCanViewShiftTags': value['employeesCanViewShiftTags'],
        'shiftSwappingEnabled': value['shiftSwappingEnabled'],
        'shiftSwapsRequireApproval': value['shiftSwapsRequireApproval'],
        'swapCutOffHoursBeforeShift': value['swapCutOffHoursBeforeShift'],
        'enableOpenShifts': value['enableOpenShifts'],
        'paidBreakName': value['paidBreakName'],
        'unpaidBreakName': value['unpaidBreakName'],
    };
}

