const getAppRoute = () => process.env.VUE_APP_URL;
const getPortalAppRoute = () => process.env.VUE_APP_PORTAL_URL;

const supportRoute = 'https://support.shiftie.co/support';
const websiteRoute = 'https://shiftie.co';

export const links = {
  // App routes
  login: `https://${getAppRoute()}/login`,
  logout: `https://${getAppRoute()}/logout`,
  dashboard: `https://${getAppRoute()}/dashboard`,
  // Portal routes
  Portal: `https://${getPortalAppRoute()}/login`,
  // Support routes
  addNewShifts: `${supportRoute}/solutions/articles/101000410856`,
  addPersonalDetails: `${supportRoute}/solutions/articles/101000410895`,
  addTeamMembers: `${supportRoute}/solutions/articles/101000411015`,
  addUnavailability: `${supportRoute}/solutions/articles/101000411007`,
  bulkPublishShifts: `${supportRoute}/solutions/articles/101000410885`,
  clockInTroubleshooting: `${supportRoute}/solutions/articles/101000487424`,
  connectDigiTicketsAccount: `${supportRoute}/solutions/articles/101000410980`,
  editEmploymentDetails: `${supportRoute}/solutions/articles/101000410894-how-do-i-edit-an-employee-s-employment-details-desktop-`,
  editEmploymentDetailsMobile: `${supportRoute}/solutions/articles/101000410896`,
  findOutMore: `${supportRoute}/solutions/articles/101000438952-how-do-i-create-a-schedule-template-`,
  help: `${supportRoute}/home`,
  importEmployees: `${supportRoute}/solutions/articles/101000511616-how-do-i-bulk-import-employees-desktop-only-`,
  inviteEmployeeToShift: `${supportRoute}/solutions/articles/101000410892`,
  manageEmployeeAttributes: `${supportRoute}/solutions/articles/101000410966`,
  manageDocuments: `${supportRoute}/solutions/articles/101000410968`,
  manageGroups: `${supportRoute}/solutions/articles/101000431272`,
  manageJobRoles: `${supportRoute}/solutions/articles/101000410964`,
  manageLocations: `${supportRoute}/solutions/articles/101000410947-how-do-i-manage-locations-desktop-`,
  manageScheduleEvents: `${supportRoute}/solutions/articles/101000410958`,
  manageShiftTags: `${supportRoute}/solutions/articles/101000410960`,
  manageTimesheetSettings: `${supportRoute}/solutions/articles/101000410970`,
  permissionLevels: `${supportRoute}/solutions/articles/101000410853`,
  supportTicket: `${supportRoute}/tickets/new`,
  verifyPhoneNumber: `${supportRoute}/solutions/articles/101000416913`,
  // Website routes
  cookiePolicy: `${websiteRoute}/policy/cookie-policy/`,
  pricing: `${websiteRoute}/pricing/`,
  privacyPolicy: `${websiteRoute}/policy/privacy-policy/`,
  shiftieHome: `${websiteRoute}`,
  terms: `${websiteRoute}/policy/terms-conditions/`,
  whatsNew: `${websiteRoute}/whats-new/`,
  // Other routes
  cdn: 'https://cdn.shiftieapps.co.uk',
  systemStatus: 'https://shiftie.statuspage.io',
} as const;

export type ExternalLink = typeof links[keyof typeof links];
