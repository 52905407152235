
import CloseButton from '@/components/buttons/dedicated/CloseButton.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { dateTimeFormats } from '@/lang/dateTimeFormats';
import { Icon } from '@/lib/enum/Icon';
import store from '@/store';
import { formatCurrency } from '@/util/currencyFunctions';
import spacetime from 'spacetime';
import { PropType } from 'vue';
import {
  BillingPlan,
  BillingPlanIntervalEnum,
  BillingSubscription,
  SubscriptionQuote,
} from '../../../../api/v1';

export default {
  name: 'BillingSummary',
  components: {
    CloseButton,
    BubbleIcon,
    TextInput,
  },
  props: {
    package: {
      type: Object as PropType<BillingPlan>,
      required: false,
      default: null,
    },
    subscription: {
      type: Object as PropType<BillingSubscription>,
      required: false,
      default: null,
    },
    quote: {
      type: Object as PropType<SubscriptionQuote>,
      default: null,
    },
    appliedOffers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      BillingPlanIntervalEnum,
      dateTimeFormats,
      Icon,
      spacetime,
      code: '',
    };
  },
  computed: {
    timezone: () => store.getters.timezone,

    rejectedOffers() {
      return [
        ...new Set([
          ...this.appliedOffers.filter(
            (offer) => !this.quote?.appliedOffers.includes(offer),
          ),
          ...(this.quote?.unusedOffers ?? []),
        ]),
      ];
    },

    paymentDateRange() {
      const { timezone } = this;
      const startDate = this.quote.expectedStartDate.toDate(timezone);
      const endDate = this.quote.expectedRenewalDate.toDate(timezone);

      const endsNextYear = !spacetime(endDate, timezone).isSame(
        spacetime.now(timezone),
        'year',
      );
      const startString = this.$d(
        startDate,
        endsNextYear ? dateTimeFormats.dayMonthYear : dateTimeFormats.dayMonth,
      );
      const endString = this.$d(endDate, dateTimeFormats.dayMonthYear);

      return `${startString} ${this.$t('conjunction.to')} ${endString}`;
    },

    billingPlan(): BillingPlan {
      return this.package ?? this.subscription.billingPlan;
    },

    packagePrice(): number {
      return this.package?.price ?? this.subscription.price;
    },

    employeeSoftLimit(): number {
      return (
        this.package?.employeeSoftLimit ??
        this.subscription.billingSubscriptionQuota.employeeSoftLimit
      );
    },

    additionalEmployeePrice(): number {
      return (
        this.package?.additionalEmployeePrice ??
        this.subscription.additionalEmployeePrice
      );
    },
  },
  methods: {
    formatCurrency,
    addCode(code) {
      this.$emit('addOffer', code);
      this.code = '';
    },
  },
};
