/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchData
 */
export interface SearchData {
    /**
     * Allows filtering of results. Typically you can filter on any fields of the underlying model.
     * 
     * The following operators are currently supported:
     * * eq
     * * neq
     * * gt
     * * gte
     * * lt
     * * lte
     * * contains
     * * startsWith
     * * endsWith
     * * in
     * * notIn
     * 
     * Example:<br>
     * `{"where": {"companyId": {"eq": 4}, "dateOfBirth": {"gte": "2024-01-01", "lt": "2024-12-31"}}}`
     * 
     * You can also filter based on immediate relations. For example `{"where": {"jobRoles.id": {"eq": 4}}}` on `/employees` would
     * get you all the employees who had a job role with an id of "4".
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SearchData
     */
    where?: { [key: string]: object; };
}

/**
 * Check if a given object implements the SearchData interface.
 */
export function instanceOfSearchData(value: object): boolean {
    return true;
}

export function SearchDataFromJSON(json: any): SearchData {
    return SearchDataFromJSONTyped(json, false);
}

export function SearchDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchData {
    if (json == null) {
        return json;
    }
    return {
        
        'where': json['where'] == null ? undefined : json['where'],
    };
}

export function SearchDataToJSON(value?: SearchData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'where': value['where'],
    };
}

