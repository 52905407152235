import { render, staticRenderFns } from "./PlanConfirmationV2.vue?vue&type=template&id=09908f45&"
import script from "./PlanConfirmationV2.vue?vue&type=script&lang=ts&"
export * from "./PlanConfirmationV2.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports