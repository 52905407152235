/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateScheduledBreakData } from './CreateScheduledBreakData';
import {
    CreateScheduledBreakDataFromJSON,
    CreateScheduledBreakDataFromJSONTyped,
    CreateScheduledBreakDataToJSON,
} from './CreateScheduledBreakData';

/**
 * 
 * @export
 * @interface BulkCreateShiftData
 */
export interface BulkCreateShiftData {
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftData
     */
    scheduleId: number;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftData
     */
    locationId: number;
    /**
     * Allows multiple shift to assign to multiple employees
     * @type {Array<number>}
     * @memberof BulkCreateShiftData
     */
    employeeIds: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftData
     */
    jobRoleId: number;
    /**
     * Whether to offer the shift to all eligible employees
     * @type {boolean}
     * @memberof BulkCreateShiftData
     */
    open?: boolean;
    /**
     * Whether the system can auto-assign employees when they respond to an open shift
     * @type {boolean}
     * @memberof BulkCreateShiftData
     */
    requiresApproval?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof BulkCreateShiftData
     */
    startsAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BulkCreateShiftData
     */
    endsAt: Date;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof BulkCreateShiftData
     */
    showEndTime?: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof BulkCreateShiftData
     */
    showJobRole?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkCreateShiftData
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<CreateScheduledBreakData>}
     * @memberof BulkCreateShiftData
     */
    scheduledBreaks?: Array<CreateScheduledBreakData>;
    /**
     * 
     * @type {boolean}
     * @memberof BulkCreateShiftData
     */
    published?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkCreateShiftData
     */
    notes?: string;
    /**
     * How many members of staff are required to cover this period
     * @type {number}
     * @memberof BulkCreateShiftData
     */
    capacity?: number;
}

/**
 * Check if a given object implements the BulkCreateShiftData interface.
 */
export function instanceOfBulkCreateShiftData(value: object): boolean {
    if (!('scheduleId' in value)) return false;
    if (!('locationId' in value)) return false;
    if (!('employeeIds' in value)) return false;
    if (!('jobRoleId' in value)) return false;
    if (!('startsAt' in value)) return false;
    if (!('endsAt' in value)) return false;
    return true;
}

export function BulkCreateShiftDataFromJSON(json: any): BulkCreateShiftData {
    return BulkCreateShiftDataFromJSONTyped(json, false);
}

export function BulkCreateShiftDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateShiftData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'],
        'locationId': json['locationId'],
        'employeeIds': json['employeeIds'],
        'jobRoleId': json['jobRoleId'],
        'open': json['open'] == null ? undefined : json['open'],
        'requiresApproval': json['requiresApproval'] == null ? undefined : json['requiresApproval'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'showEndTime': json['showEndTime'] == null ? undefined : json['showEndTime'],
        'showJobRole': json['showJobRole'] == null ? undefined : json['showJobRole'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'scheduledBreaks': json['scheduledBreaks'] == null ? undefined : ((json['scheduledBreaks'] as Array<any>).map(CreateScheduledBreakDataFromJSON)),
        'published': json['published'] == null ? undefined : json['published'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'capacity': json['capacity'] == null ? undefined : json['capacity'],
    };
}

export function BulkCreateShiftDataToJSON(value?: BulkCreateShiftData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scheduleId': value['scheduleId'],
        'locationId': value['locationId'],
        'employeeIds': value['employeeIds'],
        'jobRoleId': value['jobRoleId'],
        'open': value['open'],
        'requiresApproval': value['requiresApproval'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'tags': value['tags'],
        'scheduledBreaks': value['scheduledBreaks'] == null ? undefined : ((value['scheduledBreaks'] as Array<any>).map(CreateScheduledBreakDataToJSON)),
        'published': value['published'],
        'notes': value['notes'],
        'capacity': value['capacity'],
    };
}

