type mappedObject<T> = { [K in keyof T]: boolean };
// Returns the first key of an object with a truthy value
export const keyOfFirstTruthyValue = <T>(
  mappedObject: mappedObject<T>,
): keyof T =>
  Object.keys(mappedObject).find((key) => mappedObject[key]) as keyof T;

/**
 * Source:
 * - https://dev.to/tipsy_dev/advanced-typescript-reinventing-lodash-get-4fhe
 * - https://gist.github.com/pffigueiredo/9161240b8c09d51ea448fd43de4d8bbc#file-nestedkeyof-ts
 */
type GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? '0' extends keyof T
    ? undefined
    : number extends keyof T
    ? T[number]
    : undefined
  : undefined;
type IndexedFieldWithPossiblyUndefined<T, Key> =
  | GetIndexedField<Exclude<T, undefined>, Key>
  | Extract<T, undefined>;
type FieldWithPossiblyUndefined<T, Key> =
  // eslint-disable-next-line no-use-before-define
  GetFieldType<Exclude<T, undefined>, Key> | Extract<T, undefined>;

export type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ? FieldWithPossiblyUndefined<T[Left], Right>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
      ? FieldWithPossiblyUndefined<
          IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>,
          Right
        >
      : undefined
    : undefined
  : P extends keyof T
  ? T[P]
  : P extends `${infer FieldKey}[${infer IndexKey}]`
  ? FieldKey extends keyof T
    ? IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
    : undefined
  : undefined;

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type TypeMatchingKeys<T, V> = {
  [K in keyof T]-?: V extends T[K] ? K : never;
}[keyof T];

export type KeysMatchingType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type ItemType<
  V extends Array<unknown> | Record<keyof unknown, unknown> = Array<unknown>,
  T = number,
> = T extends keyof V ? V[T] : never;

/**
 *
 * @param data Object source
 * @param path Path to value in format 'foo.bar[0].baz'
 * @param defaultValue Value will be used if no value be found
 *
 * @note Not support keys like 'foo.bar' inside data
 */
export function objectGetByPath<
  TData extends object,
  TPath extends NestedKeyOf<TData>,
  TDefault = GetFieldType<TData, TPath>,
>(data: TData, path: TPath, defaultValue?: TDefault): unknown | TDefault {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce((value, key) => value?.[key], data as any);

  return value !== undefined ? value : defaultValue;
}

export type AnyGetter<T extends object = object, R = unknown> =
  | ((data: T) => R)
  | NestedKeyOf<T>
  | string;

/**
 *
 * @param data Object source
 * @param pathOrGetter Path in format 'foo.bar[0].baz' or getter function
 * @param defaultValue Value will be used if no value be found
 *
 * @note Not support keys like 'foo.bar' inside data
 */
export function getFromObject<
  TData extends object,
  TPathOrGetter extends AnyGetter<TData>,
  TDefault = undefined,
>(
  data: TData,
  pathOrGetter: TPathOrGetter,
  defaultValue?: TDefault,
): unknown | TDefault {
  if (typeof pathOrGetter === 'string' || typeof pathOrGetter === 'number') {
    return objectGetByPath(
      data,
      `${pathOrGetter}` as NestedKeyOf<TData>,
      defaultValue,
    );
  }
  const result =
    typeof pathOrGetter === 'symbol'
      ? data[pathOrGetter as keyof TData]
      : pathOrGetter(data);
  return result !== undefined ? result : defaultValue;
}

export const removeEmptyProperties = <T extends Object>(
  object: T,
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(object).filter(([, v]) => {
      if (Array.isArray(v) || typeof v === 'string' || v instanceof String) {
        return v.length;
      }
      return v !== null && v !== undefined;
    }),
  ) as Partial<T>;
};

export const objectHasOwn = (obj: object, key: string | number | symbol) =>
  Object.hasOwnProperty.call(obj, key);

// Source:
// https://github.com/reduxjs/react-redux/blob/master/src/utils/shallowEqual.ts
// Patched shellEqual to support Date objects
export const shallowEqual = (a: unknown, b: unknown): boolean => {
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  if (Object.is(a, b)) return true;

  if (
    typeof a !== 'object' ||
    a === null ||
    typeof b !== 'object' ||
    b === null
  ) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;

    for (let i = 0; i < b.length; i += 1) {
      if (!Object.is(a[i], b[i])) return false;
    }

    return true;
  }
  if (Array.isArray(a) !== Array.isArray(b)) return false;

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i += 1) {
    const key = keysA[i];
    if (!objectHasOwn(b, key) || !Object.is(a[key], b[key])) {
      return false;
    }
  }

  return true;
};

// Returns an object including specified keys
export const refineObject = <K extends string, T extends Record<K, unknown>>(
  obj: T,
  keys: K[],
): Pick<T, K> =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key as K)),
  ) as Pick<T, K>;

// Returns an object excluding specified keys
export const objectWithout = <
  T extends Record<string | symbol, unknown>,
  K extends keyof T,
>(
  obj: T,
  keys: K[],
): Omit<T, K> =>
  Object.fromEntries(
    Object.entries(obj).filter(([k]) => !keys.includes(k as K)),
  ) as Omit<T, K>;
