import { Icon } from '@/lib/enum/Icon';
import { keyOfFirstTruthyValue } from '@/util/objectFunctions';
import spacetime from 'spacetime';
import { ScheduledBreak } from '../../../api/v1';

export const tooEarlyToStartScheduledBreak = (
  scheduledBreak: ScheduledBreak | null,
  now: Date,
  timezone: string,
): boolean =>
  scheduledBreak?.startsAt
    ? spacetime(now, timezone).isBefore(scheduledBreak.startsAt)
    : false;

export const getBreakEndTime = (
  scheduledBreak: ScheduledBreak,
  timezone: string,
): Date => {
  const breakStartsAt = spacetime(scheduledBreak.startsAt, timezone);
  const breakEndsAt = breakStartsAt.add(
    scheduledBreak.durationInMinutes,
    'minute',
  );
  return breakEndsAt.toNativeDate();
};

export const getBreakTypeIcon = (scheduledBreak: ScheduledBreak): string =>
  keyOfFirstTruthyValue({
    [Icon.MugSaucer]: !scheduledBreak.paid,
    [Icon.BurgerSoda]: scheduledBreak.paid,
  }) || '';
