import {
  ApiVersion,
  ApiVersionTypeMap,
  middlewareCreator,
} from '@/lib/api/types';
import { EnrichedError } from '@/lib/errors/enriched';
import { showError } from '@/plugins/ToastNotifications';
import { getGlobal } from '@/util/globalFunctions';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/util/storageFunctions';
import spacetime from 'spacetime';
import Auth from '../../Auth';
import i18n from '../../i18n';
import { StorageEnum } from '../enum/StorageEnum';
import { abortAndLogout } from './fetchAbort';

// Pre request middleware
export const includeCredentials: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      context.init.credentials = 'include';

      return Promise.resolve(context);
    },
  };
};

export const authenticate: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      const companyId = getGlobal('$companyId');

      if (await Auth.isAuthenticated()) {
        if (await Auth.tokenExpiresAfter(spacetime.now().add(2, 'minute'))) {
          await Auth.refreshCredentials();
        }

        const token = await Auth.getToken();

        // eslint-disable-next-line
        context.init.headers['Authorization'] = `Bearer ${token}`;
        context.init.headers['x-company-id'] = companyId;
      }

      return Promise.resolve(context);
    },
  };
};

export const addVersionHeaders = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      context.init.headers['x-client-version'] =
        process.env.VUE_APP_RELEASE_VERSION ?? '';

      return Promise.resolve(context);
    },
  };
};

export const addUriHeader = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      context.init.headers['x-spa-uri'] = window.location.toString();

      return Promise.resolve(context);
    },
  };
};

// Post request middleware
export const redirectIfUnauthenticated: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async post(
      context: ApiVersionTypeMap[A]['ResponseContext'],
    ): Promise<Response | void> {
      const { response } = context;
      // When we encounter a 401 response, stop middleware propagation (don't show any error toast)
      // and instead redirect to the logout screen with a relevant message.
      if (response.status === 401) {
        abortAndLogout();

        // If no token: "No refresh token was supplied"
        // If token expired: "You have been logged out"
        const hasToken = !!(await Auth.getToken());
        throw new EnrichedError('Unauthorised', {
          cause: response,
          hasToken,
          tokenExpiry: hasToken ? new Date(Auth.tokenExpiry * 1000) : null,
        });
      }

      return response;
    },
  };
};

export const recordApiVersion: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async post(
      context: ApiVersionTypeMap[A]['ResponseContext'],
    ): Promise<Response> {
      const { response } = context;
      const storedApiVersion = getLocalStorageItem(StorageEnum.ApiVersion);
      const receivedApiVersion = response.headers.get('x-release');
      if (receivedApiVersion && storedApiVersion !== receivedApiVersion) {
        setLocalStorageItem(StorageEnum.ApiVersion, receivedApiVersion);
      }

      return response;
    },
  };
};

export const displayErrorsAsToasts: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async post(
      context: ApiVersionTypeMap[A]['ResponseContext'],
    ): Promise<Response> {
      const { response } = context;

      if (response.status >= 400) {
        const data = await response.clone().json();

        if (data.errors && Object.keys(data.errors).length) {
          /** @todo Get a better way of showing validation errors. For the time being, we'll just show the first one. */
          // @ts-ignore
          showError(Object.values(data.errors)[0]);
        } else if (data.message) {
          showError(data.message, i18n.t('info.heading.oops'));
        }
      }

      return response;
    },
  };
};
