/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
} from './Upload';

/**
 * Represents the settings configured for a company
 * @export
 * @interface CompanySetting
 */
export interface CompanySetting {
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    logoId: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    accountOwnerId: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    primaryContactId: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySetting
     */
    allowOvertime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySetting
     */
    allowTimeOff: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySetting
     */
    employeesCanLogOwnAbsences: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySetting
     */
    showApprovedTimeOff: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanySetting
     */
    startOfWeek: CompanySettingStartOfWeekEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanySetting
     */
    timeFormat: CompanySettingTimeFormatEnum;
    /**
     * The default number of hours taken from an employee's leave allowance for a day's worth of leave
     * @type {number}
     * @memberof CompanySetting
     */
    defaultLeaveHoursUsedPerDay: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    overtimeDailyThreshold: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    overtimeWeeklyThreshold: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanySetting
     */
    doubleOvertimeDailyThreshold: number | null;
    /**
     * An ISO 4217 currency code.
     * @type {string}
     * @memberof CompanySetting
     */
    currencyCode: string;
    /**
     * 
     * @type {Upload}
     * @memberof CompanySetting
     */
    logo: Upload;
}

/**
* @export
* @enum {string}
*/
export enum CompanySettingStartOfWeekEnum {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}
/**
* @export
* @enum {string}
*/
export enum CompanySettingTimeFormatEnum {
    _12 = '12',
    _24 = '24'
}


/**
 * Check if a given object implements the CompanySetting interface.
 */
export function instanceOfCompanySetting(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('logoId' in value)) return false;
    if (!('accountOwnerId' in value)) return false;
    if (!('primaryContactId' in value)) return false;
    if (!('allowOvertime' in value)) return false;
    if (!('allowTimeOff' in value)) return false;
    if (!('employeesCanLogOwnAbsences' in value)) return false;
    if (!('showApprovedTimeOff' in value)) return false;
    if (!('startOfWeek' in value)) return false;
    if (!('timeFormat' in value)) return false;
    if (!('defaultLeaveHoursUsedPerDay' in value)) return false;
    if (!('overtimeDailyThreshold' in value)) return false;
    if (!('overtimeWeeklyThreshold' in value)) return false;
    if (!('doubleOvertimeDailyThreshold' in value)) return false;
    if (!('currencyCode' in value)) return false;
    if (!('logo' in value)) return false;
    return true;
}

export function CompanySettingFromJSON(json: any): CompanySetting {
    return CompanySettingFromJSONTyped(json, false);
}

export function CompanySettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySetting {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'logoId': json['logoId'],
        'accountOwnerId': json['accountOwnerId'],
        'primaryContactId': json['primaryContactId'],
        'allowOvertime': json['allowOvertime'],
        'allowTimeOff': json['allowTimeOff'],
        'employeesCanLogOwnAbsences': json['employeesCanLogOwnAbsences'],
        'showApprovedTimeOff': json['showApprovedTimeOff'],
        'startOfWeek': json['startOfWeek'],
        'timeFormat': json['timeFormat'],
        'defaultLeaveHoursUsedPerDay': json['defaultLeaveHoursUsedPerDay'],
        'overtimeDailyThreshold': json['overtimeDailyThreshold'],
        'overtimeWeeklyThreshold': json['overtimeWeeklyThreshold'],
        'doubleOvertimeDailyThreshold': json['doubleOvertimeDailyThreshold'],
        'currencyCode': json['currencyCode'],
        'logo': UploadFromJSON(json['logo']),
    };
}

export function CompanySettingToJSON(value?: CompanySetting | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'logoId': value['logoId'],
        'accountOwnerId': value['accountOwnerId'],
        'primaryContactId': value['primaryContactId'],
        'allowOvertime': value['allowOvertime'],
        'allowTimeOff': value['allowTimeOff'],
        'employeesCanLogOwnAbsences': value['employeesCanLogOwnAbsences'],
        'showApprovedTimeOff': value['showApprovedTimeOff'],
        'startOfWeek': value['startOfWeek'],
        'timeFormat': value['timeFormat'],
        'defaultLeaveHoursUsedPerDay': value['defaultLeaveHoursUsedPerDay'],
        'overtimeDailyThreshold': value['overtimeDailyThreshold'],
        'overtimeWeeklyThreshold': value['overtimeWeeklyThreshold'],
        'doubleOvertimeDailyThreshold': value['doubleOvertimeDailyThreshold'],
        'currencyCode': value['currencyCode'],
        'logo': UploadToJSON(value['logo']),
    };
}

