
import CheckEnvelopeSvg from '@/assets/CheckEnvelopeSvg.vue';
import ClipboardListCheckSvg from '@/assets/ClipboardListCheckSvg.vue';
import ClipboardWithAvatar from '@/assets/ClipboardWithAvatar.vue';
import ClockEmployeeCardSvg from '@/assets/ClockEmployeeCardSvg.vue';
import LocationMarkersSvg from '@/assets/LocationMarkersSvg.vue';
import Button from '@/components/buttons/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import { EventsEnum } from '@/lib/enum/events';
import { userCan } from '@/lib/permission/userCan';
import { keyOfFirstTruthyValue } from '@/util/objectFunctions';
import { capitalise } from '@/util/stringFunctions';
import { PropType } from 'vue';
import { BillingPlanFeaturesEnum as FeatureNameEnum } from '../../../api/v1';
import InputGroup from '../form/InputGroup.vue';

export default {
  name: 'LockedFeatureDialog',
  components: {
    LocationMarkersSvg,
    Button,
    InputGroup,
    Dialog,
    CheckEnvelopeSvg,
    ClipboardListCheckSvg,
    ClockEmployeeCardSvg,
    ClipboardWithAvatar,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: String as PropType<FeatureNameEnum>,
      required: true,
    },
  },
  data() {
    return {
      userCan,
      FeatureNameEnum,
    };
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) {
          this.$emit('close');
        }
      },
    },

    featureName(): string {
      return keyOfFirstTruthyValue({
        [this.$tc('entities.message', 2)]:
          this.feature === FeatureNameEnum.TeamMessaging,
        [this.$tc('entities.multipleLocation', 2)]:
          this.feature === FeatureNameEnum.MultiVenue,
        [this.$tc(`enum.feature.${this.feature}`)]: true,
      }).toLowerCase();
    },

    isSingularFeature(): boolean {
      const plurals = [FeatureNameEnum.ClockInOut];
      return plurals.includes(this.feature);
    },

    featureBullets(): string[] {
      const bulletsByFeature: Partial<Record<FeatureNameEnum, string[]>> = {
        [FeatureNameEnum.Timesheets]: [
          this.$tc('feature.bullets.timesheets.reviewClockedTimes'),
          this.$tc('feature.bullets.timesheets.trackLateTrends'),
          this.$tc('feature.bullets.timesheets.trackTeamHours'),
        ],
        [FeatureNameEnum.TeamMessaging]: [
          this.$tc('feature.bullets.teamMessaging.keyUpdates'),
          this.$tc('feature.bullets.teamMessaging.reviewPreviousMessages'),
          this.$tc(
            'feature.bullets.teamMessaging.emailSpecificGroupsOrMembers',
          ),
        ],
        [FeatureNameEnum.ClockInOut]: [
          this.$tc('feature.bullets.clockInOut.clockingForShifts'),
          this.$tc('feature.bullets.clockInOut.restrictClockingLocation'),
          this.$tc('feature.bullets.clockInOut.seeWhosOnTime'),
        ],
        [FeatureNameEnum.Reports]: [
          this.$tc('feature.bullets.reports.viewAndCompareLastRecords'),
          this.$tc('feature.bullets.reports.exportPastRecords'),
          this.$tc('feature.bullets.reports.gainValuableInsights'),
        ],
        [FeatureNameEnum.MultiVenue]: [
          this.$tc('feature.bullets.multiVenue.scheduleShifts'),
          this.$tc('feature.bullets.multiVenue.controlTimeClockSettings'),
          this.$tc('feature.bullets.multiVenue.administrateTimesheets'),
        ],
      };
      return bulletsByFeature[this.feature] ?? [];
    },
  },
  methods: {
    capitalise,

    async upgrade() {
      if (!userCan.manageBilling()) {
        return;
      }
      this.$root.$emit(EventsEnum.TogglePackageDialog);
      this.open = false;
    },
  },
};
