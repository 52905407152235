import i18n from '@/i18n';
import { VisualTheme } from '@/lib/enum/Colour';
import {
  isMissing,
  withinLateGracePeriod,
} from '@/lib/schedule-v2/shiftV2Status';
import { isFuture, isPast, minutesBetweenDates } from '@/util/dateArithmetic';
import { keyOfFirstTruthyValue } from '@/util/objectFunctions';
import {
  Shift,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '../../../api/v1';

export const timeClockStatus = (
  timezone,
  shift: Shift | null,
  timesheet: TimesheetEntry | null,
  gracePeriod: number,
): {
  label: string;
  colour: string;
  labelColour: string;
  borderColour: string;
  dotColour: VisualTheme;
} => {
  // NOTE: The order of the below status properties matters, top of the list is highest priority

  // Due in - no timesheet, before shift start time
  const dueIn = !timesheet && shift && isFuture(shift.startsAt);
  // Late - no timesheet after shift start time before clock in grace period
  const late =
    !timesheet &&
    shift &&
    isMissing(shift, timesheet) &&
    withinLateGracePeriod(shift, gracePeriod, timezone);
  // Not clocked in - no timesheet after the clock in grace period
  const notClockedIn =
    shift &&
    isMissing(shift, timesheet) &&
    !withinLateGracePeriod(shift, gracePeriod, timezone) &&
    isFuture(shift.endsAt);
  // Did not clock in - after shift end time and is missing
  const didNotClockIn =
    shift && isMissing(shift, timesheet) && isPast(shift.endsAt);
  // Clocked-in - timesheet in progress
  const clockedIn =
    timesheet && timesheet.status === TimesheetEntryStatusEnum.Started;
  // Break - timesheet on break
  const onBreak =
    timesheet && timesheet.status === TimesheetEntryStatusEnum.Break;
  // Clocked-out - timesheet completed
  const clockedOut =
    timesheet && timesheet.status === TimesheetEntryStatusEnum.Completed;

  const minutesUntilShiftStarts = shift
    ? minutesBetweenDates(shift.startsAt, new Date(), timezone)
    : 0;

  const label = {
    [String(
      i18n.t('clockIn.status.startsIn', { minutes: minutesUntilShiftStarts }),
    )]: minutesUntilShiftStarts < 15 && dueIn,
    [String(i18n.t('clockIn.status.clockedOut'))]: clockedOut,
    [String(i18n.t('clockIn.status.clockedIn'))]: clockedIn,
    [String(i18n.t('clockIn.status.break'))]: onBreak,
    [String(i18n.t('clockIn.status.late'))]: late,
    [String(i18n.t('clockIn.status.notClockedIn'))]: notClockedIn,
    [String(i18n.t('clockIn.status.didNotClockIn'))]: didNotClockIn,
  };

  const labelColour = {
    'gray-700': dueIn || clockedOut,
    green: onBreak,
    white: true,
  };

  const borderColour = {
    green: onBreak,
    none: true,
  };

  const colour = {
    'purple-50': dueIn || clockedOut,
    green: clockedIn,
    white: onBreak,
    yellow: late,
    red: didNotClockIn || notClockedIn,
  };

  const dotColour = {
    [VisualTheme.Disabled]: clockedOut,
    [VisualTheme.Success]: clockedIn || onBreak,
    [VisualTheme.Warning]: late,
    [VisualTheme.Danger]: notClockedIn || didNotClockIn,
  };

  return {
    // From the object keys cycle through the object until hit a true condition unless null
    label: keyOfFirstTruthyValue(label) as string,
    colour: keyOfFirstTruthyValue(colour) as string,
    labelColour: keyOfFirstTruthyValue(labelColour) as string,
    borderColour: keyOfFirstTruthyValue(borderColour) as string,
    dotColour: keyOfFirstTruthyValue(dotColour),
  };
};

export const isScheduledBreakClocked = (
  timesheet: TimesheetEntry,
  scheduledBreakId: number,
): boolean => {
  if (!timesheet) {
    throw new Error('Timesheet Entry should be present');
  }

  return !!timesheet.breaks.find(
    (b) => b.scheduledBreakId === scheduledBreakId,
  );
};

export const isScheduledBreakInProgress = (
  timesheet: TimesheetEntry,
  scheduledBreakId: number,
): boolean => {
  const timesheetBreak = timesheet.breaks.find(
    (b) => b.scheduledBreakId === scheduledBreakId,
  );
  return timesheetBreak ? !timesheetBreak.endedAt : false;
};
