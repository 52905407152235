
import PercentageBar from '@/components/graph/PercentageBar.vue';
import { Icon } from '@/lib/enum/Icon';
import store from '@/store';
import { BatchJob, BatchJobState } from '@/stores/jobs';
import { viewport } from '@/util/windowFunctions';
import { BatchJobLogTypeEnum } from '../../api/v1';

export default {
  name: 'BackgroundJobs',
  components: {
    PercentageBar,
  },
  data() {
    return {
      Icon,
      viewport,
      BatchJobState,
      open: false,
    };
  },
  computed: {
    jobs: (): BatchJob[] => Object.values(store.state.jobs.jobs),
    isInProgress(): boolean {
      return this.jobs.some((j) => j.state === BatchJobState.Running);
    },
    hasErrors(): boolean {
      return this.jobs.some((j) => j.state === BatchJobState.Failed);
    },
  },
  watch: {
    jobs(newJobs, oldJobs) {
      if (newJobs.length > oldJobs.length) {
        this.open = true;
      }
      if (!newJobs.length) {
        this.open = false;
      }
    },
  },
  methods: {
    deleteJob(id: string) {
      store.dispatch('jobs/delete', id);
    },
    getJobName(type: BatchJobLogTypeEnum): string {
      return (
        {
          [BatchJobLogTypeEnum.ApplyScheduleTemplate]: this.$tc(
            'backgroundJobs.types.applyScheduleTemplate',
          ),
          [BatchJobLogTypeEnum.BulkClockIn]: this.$tc(
            'backgroundJobs.types.bulkClockIn',
          ),
          [BatchJobLogTypeEnum.BulkClockOut]: this.$tc(
            'backgroundJobs.types.bulkClockOut',
          ),
          [BatchJobLogTypeEnum.CopyShifts]: this.$tc(
            'backgroundJobs.types.copyShifts',
          ),
          [BatchJobLogTypeEnum.EmployeeImport]: this.$tc(
            'backgroundJobs.types.employeeImport',
          ),
          [BatchJobLogTypeEnum.PublishShifts]: this.$tc(
            'backgroundJobs.types.publishShifts',
          ),
          [BatchJobLogTypeEnum.RecalculateWorkingTimeLost]: this.$tc(
            'backgroundJobs.types.recalculateWorkingTimeLost',
          ),
          [BatchJobLogTypeEnum.BulkConvertShiftCosts]: this.$tc(
            'backgroundJobs.types.BulkConvertShiftCosts',
          ),
          [BatchJobLogTypeEnum.BulkConvertTimesheetEntryRates]: this.$tc(
            'backgroundJobs.types.BulkConvertTimesheetEntryRates',
          ),
          [BatchJobLogTypeEnum.BulkConvertEmployeeBaseRates]: this.$tc(
            'backgroundJobs.types.BulkConvertEmployeeBaseRates',
          ),
          [BatchJobLogTypeEnum.BulkConvertEmployeeJobRoleRates]: this.$tc(
            'backgroundJobs.types.BulkConvertEmployeeJobRoleRates',
          ),
        }[type] ?? this.$tc('backgroundJobs.types.generic')
      );
    },
  },
};
