
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { BubbleIconColour, InlineDialogTheme } from '@/lib/enum/Colour';
import { Icon, IconType } from '@/lib/enum/Icon';
import { PropType } from 'vue';

export default {
  name: 'InlineDialog',
  components: { BubbleIcon },
  props: {
    header: {
      type: [String, null],
      default: null,
    },
    message: {
      type: [String, null],
      default: null,
    },
    icon: {
      type: String as PropType<IconType | null>,
      default: null,
    },
    type: {
      type: String as PropType<InlineDialogTheme>,
      default: InlineDialogTheme.Warning,
    },
    // There is no method to detect if we need to align the icon and text centrally
    // other than by developer eye, so we'll use a prop to specify this.
    alignCenter: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconBgColour(): BubbleIconColour {
      return {
        [InlineDialogTheme.Warning]: BubbleIconColour['bright-yellow'],
        [InlineDialogTheme.Error]: BubbleIconColour['bright-red'],
        [InlineDialogTheme.Info]: BubbleIconColour['bright-blue'],
        [InlineDialogTheme.Hint]: BubbleIconColour['pink-300'],
      }[this.type];
    },

    mainIcon(): IconType {
      return (
        this.icon ??
        {
          [InlineDialogTheme.Warning]: Icon.TriangleExclamation,
          [InlineDialogTheme.Error]: Icon.TriangleExclamation,
          [InlineDialogTheme.Info]: Icon.Info,
          [InlineDialogTheme.Hint]: Icon.LightBulbOn,
        }[this.type]
      );
    },
  },
};
