
import Button from '@/components/buttons/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import InputGroup from '@/components/form/InputGroup.vue';
import IconMessageBlock from '@/components/info/IconMessageBlock.vue';
import InlineDialog from '@/components/info/InlineDialog.vue';
import RadioButtons from '@/components/inputs/RadioButtons.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import Map from '@/components/map/Map.vue';
import MapPlaceholder from '@/components/map/MapPlaceholder.vue';
import { dateTimeFormats } from '@/lang/dateTimeFormats';
import { links } from '@/lang/urlLinks';
import {
  getEarliestClockInTimeString,
  userIsWithinClockingDistance,
} from '@/lib/clock-in/clockInFunctions';
import { ClockInDialogTypeEnum } from '@/lib/enum/clock-in/ClockInDialogTypeEnum';
import { Icon } from '@/lib/enum/Icon';
import { IconSizes } from '@/lib/enum/IconSizes';
import { EnrichedError } from '@/lib/errors/enriched';
import { clockInFromPersonalDevicesAtLocationFilter } from '@/lib/location/locationFilters';
import { isScheduledBreakClocked } from '@/lib/timesheets/timesheetFunctions';
import { Coordinates } from '@/plugins/mapbox';
import store from '@/store';
import { sortBy } from '@/util/arrayFunctions';
import { addMinutes } from '@/util/dateArithmetic';
import { PropType } from 'vue';
import {
  Employee,
  Location,
  ScheduledBreak,
  ScheduleSettings,
  Shift,
  TimesheetEntry,
} from '../../../api/v1';
import { round } from '../../util/mathFunctions';

export default {
  name: 'ClockInDialogV2',

  components: {
    MapPlaceholder,
    InlineDialog,
    Dialog,
    Map,
    IconMessageBlock,
    InputGroup,
    SelectInput,
    Button,
    BubbleIcon,
    RadioButtons,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String as PropType<keyof typeof ClockInDialogTypeEnum>,
      required: true,
    },
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
    shift: {
      type: Object as PropType<Shift | null>,
      default: null,
    },
    timesheet: {
      type: Object as PropType<TimesheetEntry | null>,
      default: null,
    },
    locationId: {
      type: [Number, null],
      default: null,
    },
    earlyBreakId: {
      type: [Number, null],
      default: null,
    },
    onClick: {
      type: Function as PropType<(any?) => void>,
      required: true,
    },
  },

  data() {
    return {
      Icon,
      links,
      ClockInDialogTypeEnum,
      IconSizes,

      selectedLocationId: null as number,
      fetchingGpsData: false,
      userCoordinates: undefined as Coordinates,
      selectedBreakId: this.timesheet
        ? sortBy(this.shift?.scheduledBreaks ?? [], 'startsAt')?.find(
            (sb) => !isScheduledBreakClocked(this.timesheet, sb.id),
          )?.id ?? null
        : null,
    };
  },

  computed: {
    earlyClockInThresholdInMinutes: (): number | null =>
      store.state.settings.clockingSettings.earlyClockInPeriod,
    scheduleSettings: (): ScheduleSettings =>
      store.state.settings.scheduleSettings,
    timezone: (): string => store.getters.timezone,
    locations: (): Location[] =>
      store.getters['locations/locationsWithClockInFromPersonalDevicesEnabled'],

    open: {
      get(): boolean {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },

    filteredEmployeeLocations() {
      return this.locations.filter(
        clockInFromPersonalDevicesAtLocationFilter(this.employee),
      );
    },

    location(): Location | null {
      return this.locations.find((l) => l.id === this.locationId) ?? null;
    },

    employeesMustBeAtLocation(): boolean {
      return !!this.location?.clockInGeoFenceInMeters;
    },

    userDistanceFromLocation(): number | null {
      const { longitude, latitude } = this.location || {};
      if (!this.userCoordinates || [longitude, latitude].includes(null)) {
        return null;
      }
      // eslint-disable-next-line global-require
      const haversine = require('haversine');

      return Math.round(
        haversine(
          {
            longitude,
            latitude,
          },
          {
            longitude: this.userCoordinates[0],
            latitude: this.userCoordinates[1],
          },
          { unit: 'meter' },
        ),
      );
    },

    userIsWithinClockingDistance(): boolean {
      if (!this.employeesMustBeAtLocation) {
        return true;
      }
      if (!this.userCoordinates || !this.location) {
        return false;
      }
      return userIsWithinClockingDistance(this.location, this.userCoordinates);
    },

    selectedScheduledBreak(): ScheduledBreak | null {
      const breakId = this.selectedBreakId ?? this.earlyBreakId;
      return this.shift && breakId
        ? this.shift.scheduledBreaks.find((sb) => sb.id === breakId)
        : null;
    },

    bubbleIcon() {
      const bubbleIcon = {
        [ClockInDialogTypeEnum.EarlyClockIn]: {
          icon: Icon.Hand,
          colour: 'yellow',
        },
        [ClockInDialogTypeEnum.ChooseLocation]: {
          icon: Icon.TriangleExclamation,
          colour: 'yellow',
        },
        [ClockInDialogTypeEnum.EarlyStartBreak]: {
          icon: Icon.Hand,
          colour: 'yellow',
        },
        [ClockInDialogTypeEnum.StartBreak]: {
          icon: Icon.MugSaucer,
          colour: 'default',
        },
        [ClockInDialogTypeEnum.ChooseBreak]: {
          icon: Icon.MugSaucer,
          colour: 'default',
        },
      };

      return bubbleIcon[this.type] || null;
    },

    header() {
      const headerString = {
        [ClockInDialogTypeEnum.EarlyClockIn]: this.$t(
          'modal.heading.holdYourHorses',
        ),
        [ClockInDialogTypeEnum.ChooseLocation]: this.$t(
          'clockIn.unscheduled.confirmLocation',
        ),
        [ClockInDialogTypeEnum.ClockIn]: this.shift
          ? this.$t('modal.heading.clockIn')
          : this.$t('modal.heading.unscheduledClockIn'),
        [ClockInDialogTypeEnum.EarlyStartBreak]: `${this.$t(
          'info.heading.holdUp',
        )}!`,
        [ClockInDialogTypeEnum.StartBreak]:
          this.shift && this.selectedScheduledBreak
            ? this.getScheduledBreakTitle(this.selectedScheduledBreak)
            : this.$t('modal.heading.startBreak'),
        [ClockInDialogTypeEnum.ChooseBreak]: this.$tc(
          'modal.heading.selectBreak',
        ),
        [ClockInDialogTypeEnum.EndBreak]: this.$t('modal.heading.endBreak'),
        [ClockInDialogTypeEnum.ClockOut]: this.$t('modal.heading.clockOut'),
      };

      return headerString[this.type] || '';
    },

    bodyText() {
      const bodyString = {
        [ClockInDialogTypeEnum.EarlyClockIn]: this.$t(
          'clockIn.early.clockInUpTo',
          {
            minutes: this.earlyClockInThresholdInMinutes,
          },
        ),
        [ClockInDialogTypeEnum.ChooseLocation]: this.$t(
          'clockIn.chooseLocation',
        ),
        [ClockInDialogTypeEnum.EarlyStartBreak]: this.$t(
          'clockIn.early.itsNotTimeToStartBreak',
        ),
        [ClockInDialogTypeEnum.StartBreak]:
          this.shift && this.selectedScheduledBreak
            ? this.getScheduledBreakInfo(this.selectedScheduledBreak)
            : this.$t('modal.body.startBreak'),
      };

      return bodyString[this.type] || '';
    },

    bodyExtra(): string {
      if (this.type === ClockInDialogTypeEnum.EarlyClockIn) {
        const earliestClockInTime = getEarliestClockInTimeString(
          this.earlyClockInThresholdInMinutes,
          this.shift,
        );
        return this.$tc('clockIn.early.clockInFrom', 1, {
          time: earliestClockInTime,
        });
      }
      if (
        this.type === ClockInDialogTypeEnum.EarlyStartBreak &&
        this.selectedScheduledBreak
      ) {
        const time = this.$d(
          this.selectedScheduledBreak.startsAt,
          dateTimeFormats.hourMinute,
        );
        return this.$tc('clockIn.early.yourBreakIsScheduledFor', 1, { time });
      }
      return '';
    },

    button() {
      const standardButton = {
        label: '',
        colour: 'pink',
        action: () => this.onClick(),
      };
      const partial = {
        [ClockInDialogTypeEnum.EarlyClockIn]: {
          label: this.$t('button.gotIt'),
        },
        [ClockInDialogTypeEnum.ClockIn]: {
          label: this.$t('button.confirmClockIn'),
        },
        [ClockInDialogTypeEnum.ChooseLocation]: {
          label: this.$t('button.confirm'),
          action: () => {
            const selectedLocation = this.filteredEmployeeLocations.find(
              (l) => l.id === this.selectedLocationId,
            );
            this.onClick(selectedLocation);
          },
        },
        [ClockInDialogTypeEnum.EarlyStartBreak]: {
          label: this.$t('button.gotIt'),
        },
        [ClockInDialogTypeEnum.StartBreak]: {
          label: this.$t('button.startBreak'),
          action: () => this.onClick(this.selectedScheduledBreak ?? null),
        },
        [ClockInDialogTypeEnum.ChooseBreak]: {
          label: this.$t('button.startBreak'),
          action: () => this.onClick(this.selectedScheduledBreak ?? null),
        },
        [ClockInDialogTypeEnum.EndBreak]: {
          label: this.$t('button.confirmEndBreak'),
        },
        [ClockInDialogTypeEnum.ClockOut]: {
          label: this.$t('button.confirmClockOut'),
        },
      };

      return partial[this.type]
        ? { ...standardButton, ...partial[this.type] }
        : null;
    },

    showSelect() {
      return this.type === ClockInDialogTypeEnum.ChooseLocation;
    },

    showCancel() {
      return [
        ClockInDialogTypeEnum.ChooseLocation,
        ClockInDialogTypeEnum.StartBreak,
        ClockInDialogTypeEnum.ChooseBreak,
      ].includes(this.type);
    },

    showLocationRestrictions() {
      return [
        ClockInDialogTypeEnum.ClockIn,
        ClockInDialogTypeEnum.ClockOut,
        ClockInDialogTypeEnum.EndBreak,
      ].includes(this.type);
    },

    chooseBreakList(): {
      value: number;
      name: string;
      disabled: boolean;
      scheduledBreak: ScheduledBreak;
    }[] {
      const { scheduledBreaks }: { scheduledBreaks: ScheduledBreak[] | null } =
        this.shift || {};
      if (scheduledBreaks?.length) {
        return sortBy(scheduledBreaks, 'startsAt').map((sb) => ({
          value: sb.id,
          name: '',
          disabled:
            this.timesheet && isScheduledBreakClocked(this.timesheet, sb.id), // do not allow start same break more than once
          scheduledBreak: sb,
        }));
      }
      return [];
    },
  },

  watch: {
    type() {
      this.selectedBreakId = null;
    },
  },

  mounted() {
    if (this.showLocationRestrictions && this.employeesMustBeAtLocation) {
      this.fetchingGpsData = true;
      if (!('geolocation' in navigator)) {
        this.fetchingGpsData = false;
        throw new EnrichedError('Could not get location', {
          hasGeolocation: false,
        });
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userCoordinates = [
            position.coords.longitude,
            position.coords.latitude,
          ];
          this.fetchingGpsData = false;
        },
        (e) => {
          this.userCoordinates = undefined;
          this.fetchingGpsData = false;
          throw new EnrichedError('Could not get location', {
            hasGeolocation: true,
            error: e,
          });
        },
      );
    }
  },

  methods: {
    round,

    getScheduledBreakTitle(scheduledBreak: ScheduledBreak): string {
      const { paidBreakName, unpaidBreakName } = this.scheduleSettings || {};

      if (scheduledBreak.paid) {
        return paidBreakName
          ? `${paidBreakName} (${this.$tc('label.paid')})`
          : this.$tc('label.paidBreak');
      }
      return unpaidBreakName
        ? `${unpaidBreakName} (${this.$tc('label.unpaid')})`
        : this.$tc('label.unpaidBreak');
    },
    getScheduledBreakInfo({
      startsAt,
      durationInMinutes,
    }: ScheduledBreak): string {
      const duration = this.$tc('unitWithAmount.min', durationInMinutes);

      if (startsAt) {
        const endsAt = addMinutes(startsAt, durationInMinutes, this.timezone);

        const startTime = this.$d(startsAt, dateTimeFormats.hourMinute);
        const endTime = this.$d(endsAt, dateTimeFormats.hourMinute);

        return `${duration} | ${startTime} - ${endTime}`;
      }

      return duration;
    },
  },
};
