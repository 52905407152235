import Vue from 'vue';
import Vuex from 'vuex';

// Store modules
import app from '@/stores/app';
import appWarnings from '@/stores/appWarnings';
import billing from '@/stores/billing';
import entities, { setCommit } from '@/stores/entities';
import jobs from '@/stores/jobs';
import locations from '@/stores/locations';
import requests from '@/stores/requests';
import schedule from '@/stores/schedule';
import settings from '@/stores/settings';

import Auth from '@/Auth';
import { initDateTimeAndLocale } from '@/i18n';
import { authApiFactory } from '@/lib/api/factory';
import { controller } from '@/lib/api/fetchAbort';
import { isActiveCompany } from '@/lib/company/companyFunctions';
import {
  entityRepository,
  setEntityRepositoryCommit,
} from '@/lib/realtime/EntityRepository';
import { findEntity } from '@/lib/realtime/realtimeFunctions';
import { weakEntityRepository } from '@/lib/realtime/weak/realtimeFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import { setSentryCompanyContext } from '@/plugins/sentry';
import { websocketService } from '@/plugins/Websockets';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import entitySubscriptions from '@/stores/entitySubscriptions';
import { getGlobal } from '@/util/globalFunctions';
import { extractPermissionsFromAccessRoles } from '@/util/permissionFunctions';
import { UserApi } from '../api/auth';
import {
  AccessRole,
  AccessRolesPermissionPermissionEnum,
  Company,
  Employee,
  User,
} from '../api/v1';
import {
  ApiRequest,
  authApi,
  authUsersApi,
  clockingSettingsApi,
  companySettingsApi,
  employeeApi,
  leaveSettingsApi,
  scheduleSettingsApi,
  settingsApi,
} from './api';

Vue.use(Vuex);

interface LoggedInEmployee extends Employee {
  accessRoles: AccessRole[];
}

const store = new Vuex.Store({
  // @ts-ignore
  namespaced: true,
  // @todo enable this
  // strict: process.env.NODE_ENV !== 'production',

  state: {
    reloadRequired: false,

    authenticatedUser: null as User,
    isImpersonationSession: false as boolean,
    isEnhancedImpersonationSession: false as boolean,
    permissions: [] as AccessRolesPermissionPermissionEnum[],
    loggedInEmployee: null as LoggedInEmployee,
    managedEmployeeIds: [] as number[],
    currentCompany: null as Company,

    // Global dialog values
    showCompanyStatusModal: false as boolean,
    // Old and new access role ids
    permissionsUpdated: null as { old: number; new: number },
    // Boolean for company plan update
    planUpdated: false as boolean,
  } as any,

  modules: {
    app,
    billing,
    entities,
    entitySubscriptions,
    jobs,
    requests,
    settings,
    appWarnings,
    schedule,
    locations,
  },

  getters: {
    currentCompany(state) {
      return state.currentCompany;
    },

    locale(state): string | null {
      return state.currentCompany?.locale ?? null;
    },

    loggedInEmployee(state): LoggedInEmployee | null {
      return state.loggedInEmployee;
    },

    timezone(state) {
      return state.currentCompany ? state.currentCompany.timezone : null;
    },

    user: (state) => state.authenticatedUser,
  },

  mutations: {
    RELOAD_REQUIRED(state, reloadRequired) {
      state.reloadRequired = reloadRequired;
    },

    LOGOUT(state) {
      state.authenticatedUser = null;
    },

    USER_SWITCH_CLEAN_UP(state) {
      // @todo: When theres more time this can be sorted using the default store const
      // This pattern could then be replicated to restore all states within all stores
      state.permissions = [];
      state.loggedInEmployee = null;
    },

    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },

    UPDATE_COMPANY(state, company: Company) {
      state.currentCompany = company;
    },

    SET_MANAGED_EMPLOYEE_IDS(state, employeeIds: number[]) {
      state.managedEmployeeIds = employeeIds;
    },

    SET_AUTHENTICATED_USER(state, user: User) {
      state.authenticatedUser = user;
    },

    SET_IMPERSONATION_SESSION(state, isImpersonationSession: boolean) {
      state.isImpersonationSession = isImpersonationSession;
    },

    SET_ENHANCED_IMPERSONATION_SESSION(
      state,
      isEnhancedImpersonationSession: boolean,
    ) {
      state.isEnhancedImpersonationSession = isEnhancedImpersonationSession;
    },

    SET_LOGGED_IN_EMPLOYEE(state, employee: LoggedInEmployee) {
      state.loggedInEmployee = employee;
    },

    UPDATE_LOGGED_IN_EMPLOYEE(state, employee: Employee) {
      if (employee.id !== state.loggedInEmployee.id) {
        throw new Error(
          'Employee id does not match the current logged in employee id',
        );
      }

      Vue.set(state, 'loggedInEmployee', {
        ...employee,
        accessRoles: state.loggedInEmployee.accessRoles,
      });
    },

    SET_CURRENT_COMPANY(state, company: Company) {
      state.currentCompany = company;
    },

    SET_COMPANY_STATUS_MODAL(state, v: boolean) {
      state.showCompanyStatusModal = v;
    },

    SET_PERMISSIONS_UPDATED(state, changedRoles: { old: number; new: number }) {
      state.permissionsUpdated = changedRoles;
    },

    SET_PLAN_UPDATED(state) {
      state.planUpdated = true;
    },
  },

  actions: {
    async fetchAuthenticatedUser({ commit }) {
      const { data: user } = await authApiFactory
        .create(UserApi, { displayErrorsAsToasts: false })
        .showAuthenticatedUser({});
      commit('SET_AUTHENTICATED_USER', user);
    },

    setImpersonationSession({ commit }, isImpersonationSession: boolean) {
      commit('SET_IMPERSONATION_SESSION', isImpersonationSession);
    },

    setEnhancedImpersonationSession(
      { commit },
      isEnhancedImpersonationSession: boolean,
    ) {
      commit(
        'SET_ENHANCED_IMPERSONATION_SESSION',
        isEnhancedImpersonationSession,
      );
    },

    reloadRequired({ commit }, reloadRequired: boolean) {
      commit('RELOAD_REQUIRED', reloadRequired);
    },

    async userCleanUpSwitch({ commit, dispatch }) {
      commit('USER_SWITCH_CLEAN_UP');
      commit('requests/REQUESTS_CLEAN_UP');
      commit('locations/LOCATIONS_CLEAN_UP');
      await dispatch('jobs/reset');
      await dispatch('settings/reset');
    },

    async logout({ commit, dispatch }, logoutEverywhere: boolean = false) {
      controller.abort();
      await authApi.logout({
        logoutEverywhere,
      });
      Auth.resetCredentials();
      dispatch('userCleanUpSwitch');
      commit('LOGOUT');
    },

    async verifyEmail({ commit }, token) {
      const { data: user } = await authUsersApi.verifyEmail({
        verifyEmailData: { token },
      });
      commit('SET_AUTHENTICATED_USER', user);
    },

    async updateUser({ commit }, user: User) {
      commit('SET_AUTHENTICATED_USER', user);
    },

    async fetchUsersTeam({ commit }) {
      // A user's team consists of anyone who they line manage,
      // *plus* if they are the primary contact on the account, anyone
      // who doesn't have a line manager
      try {
        const { data: subordinates } = await new ApiRequest(
          employeeApi,
          'listSubordinates',
        ).fetchAll();

        const ids = subordinates.map((e) => e.id);
        commit('SET_MANAGED_EMPLOYEE_IDS', ids);
      } catch {
        commit('SET_MANAGED_EMPLOYEE_IDS', []);
      }
    },

    async setLoggedInEmployee({ commit, dispatch }) {
      const { data: loggedInEmployee } = await employeeApi.showCurrentEmployee({
        _with: ['accessRoles.permissions', 'photo'],
      });
      await commit('SET_LOGGED_IN_EMPLOYEE', loggedInEmployee);
      await dispatch('fetchUsersTeam');
    },

    // Callback provided so that loading screen finishes after desired action
    async bootstrapCurrentCompany(
      { commit, dispatch, state },
      callback: Function = null,
    ) {
      const companyId = getGlobal('$companyId');
      commit('app/START_LOADING');

      // Reset all of our real-time entities
      dispatch('resetEntities', true);

      // Clean up any data related to the previous user
      await dispatch('userCleanUpSwitch');

      const company = await findEntity(Entity.Company, companyId);

      if (!company) {
        await redirect(routes.logout.route());
        return commit('app/END_LOADING');
      }

      // Refresh all global data that relates to a specific company
      commit('SET_CURRENT_COMPANY', company);
      dispatch('billing/setCompanyBillingPlan');
      dispatch('billing/setBillingOverview');
      dispatch('settings/setCompanySettings', company.companySetting);

      if (!isActiveCompany(company)) {
        commit('SET_COMPANY_STATUS_MODAL', true);
      }

      // Set the language file to correspond with the users chosen locale, defaults to 'en'
      initDateTimeAndLocale(
        company.locale,
        company.companySetting.timeFormat,
        company.timezone,
      );

      const { data: loggedInEmployee } = await employeeApi.showCurrentEmployee({
        _with: ['accessRoles.permissions', 'photo'],
      });

      // Add company & employee data to Sentry
      setSentryCompanyContext(company, loggedInEmployee);

      await websocketService.authenticate();

      // Now store all the unique permissions for this employee for easy access elsewhere
      commit(
        'SET_PERMISSIONS',
        extractPermissionsFromAccessRoles(loggedInEmployee.accessRoles),
      );

      dispatch('leaveSettings');

      await dispatch('clockingSettings');
      await dispatch('scheduleSettings');
      await dispatch('notificationSettings');
      commit('SET_LOGGED_IN_EMPLOYEE', loggedInEmployee);

      await dispatch('fetchUsersTeam');

      // It's important this comes *after* fetchUsersTeam,
      // so that manageEmployeeIds is populated
      dispatch('requests/fetchRequests', {
        loggedInEmployeeId: loggedInEmployee.id,
        managedEmployeeIds: state.managedEmployeeIds,
        timezone: company.timezone,
      });
      dispatch('locations/fetchLocations');

      if (callback) {
        callback().finally(() => {
          commit('app/END_LOADING');
        });
        return;
      }
      commit('app/END_LOADING');
    },

    async clockingSettings({ dispatch }) {
      const { data: clockingSettings } =
        await clockingSettingsApi.showClockingSettings({});
      dispatch('settings/setClockingSettings', clockingSettings);
    },

    async companySettings({ dispatch }) {
      const { data: companySettings } =
        await companySettingsApi.showCompanySettings({
          _with: ['logo'],
        });
      dispatch('settings/setCompanySettings', companySettings);
    },

    async leaveSettings({ dispatch }) {
      const { data: leaveSettings } =
        await leaveSettingsApi.showLeaveSettings();
      dispatch('settings/setLeaveSettings', leaveSettings);
    },

    async scheduleSettings({ dispatch }) {
      const { data: scheduleSettings } =
        await scheduleSettingsApi.showScheduleSettings();
      dispatch('settings/setScheduleSettings', scheduleSettings);
    },

    async notificationSettings({ dispatch }) {
      const { data: notificationSettings } =
        await settingsApi.showNotificationSettings({});
      dispatch('settings/setNotificationSettings', notificationSettings);
    },

    updateCompany({ commit }, company: Company) {
      initDateTimeAndLocale(
        company.locale,
        company.companySetting.timeFormat,
        company.timezone,
      );
      commit('UPDATE_COMPANY', company);
    },

    setPermissionsUpdated(
      { commit },
      changedRoles: { old: number; new: number },
    ) {
      commit('SET_PERMISSIONS_UPDATED', changedRoles);
    },

    setPlanUpdated({ commit }) {
      commit('SET_PLAN_UPDATED');
    },

    resetEntities({ commit }, hard?: boolean) {
      if (hard) {
        weakEntityRepository.detachedReset();
      } else {
        weakEntityRepository.softReset();
      }

      entityRepository.reset();
      commit('entities/RESET');
    },

    /**
     * Set a loding state for both dispatches as we have many places where those are used in pair.
     * There are a couple of situations (Login with its company selection and Switch Company stuff) where we only need to
     * execute 'bootstrapCurrentCompany'.
     */
    async authorizeApp(
      { commit, dispatch },
      redirectCallback: Function | null = null,
    ) {
      commit('app/START_LOADING');
      const promise = async () => {
        await dispatch('fetchAuthenticatedUser');
        await dispatch('bootstrapCurrentCompany', redirectCallback);
      };
      await promise().finally(() => commit('app/END_LOADING'));
    },
  },
});

// Yes, setting a reference to the store inside the Vuex module feels a bit clumsy,
// but see the comments in the 'loadCollection' function for reasons why.
setCommit(store.commit);
setEntityRepositoryCommit(store.commit);

export default store;
