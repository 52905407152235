import { LogoutReason } from '@/lib/auth/logoutReason';
import { Layout } from '@/lib/enum/LayoutEnum';
import { LeaveTab } from '@/lib/enum/leave';
import { RequestTabEnum } from '@/lib/enum/requests';
import { EntityName } from '@/lib/realtime/realtimeTypes';
import { SignUpPageRouteQueryType } from '@/lib/sign-up/types';
import { getNullableNumber } from '@/util/numberFunctions';
import { Route, RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-unresolved
import { Location, _RouteConfigBase } from 'vue-router/types/router';
import { BillingPlanFeaturesEnum, CompanyFeatureFlagsEnum } from '../../api/v1';

/** Our routes have optional properties in addition to those expected by vue-router */
export interface NestableRouteConfig extends _RouteConfigBase {
  childOf?: Function;
  route?(): Location;
}

export type OurRouteConfig =
  | RouteConfig
  | (NestableRouteConfig & { featureFlag?: CompanyFeatureFlagsEnum });

export type OurRouteConfigObject = { [name: string]: OurRouteConfig };

const Feature = BillingPlanFeaturesEnum;

/**
 * This function allows us to type our routes object consistently, getting warnings if we don't implement new routes
 * correctly, whilst still preserving the ability for our IDEs to navigate to parts of the object
 * (which isn't possible if you specific generic 'string' keys in Typescript).
 *
 * @see https://stackoverflow.com/a/52157355/921476
 */
export const toTypedMap = <T extends OurRouteConfigObject>(o: T): T => o;

/**
 * We define our routes in an almost identical way to how you normally would for vue-router, but with the exception
 * that we don't nest them - it's essentially a flat structure. This allows us to reference routes by name directly
 * without needing to know whether a route is nested (from a component point of view) inside another.
 *
 * We represent a parent-child relationship by adding a 'childOf' function to any route that should be nested inside
 * another. Before passing the routes to vue-router, we take a copy of the routes and transform it into the nested
 * structure that it accepts.
 */
export const routes = toTypedMap({
  notification: {
    // Standardised landing route for viewing a notification, or its associated record.
    // This avoids the backend needing to have any knowledge about how the frontend wants to organise its routes.
    // Typically used in emailed notifications as the 'call to action' URL.
    name: 'notification',
    path: '/notifications/:id',
    meta: {
      allowsCompanySetting: true,
    },
  },
  home: {
    name: 'home',
    path: '/',
    redirect: '/dashboard',
  },
  login: {
    name: 'login',
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    route: ({ redirect }: { redirect?: string } = {}) => {
      /**
       * @todo Passing route names for redirects doesn't work for paths that contain variables
       *   (eg. profile/{id}/something), so probably swap this for url-encoded full urls instead.
       */
      return {
        name: routes.login.name,
        query: { redirect },
      };
    },
    meta: {
      layout: Layout.Empty,
      allowsGuest: true,
    },
  },
  logout: {
    name: 'logout',
    path: '/logout',
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/views/auth/Logout.vue'),
    route: ({
      logoutEverywhere,
      multiLogin,
      redirect,
      reason,
    }: {
      logoutEverywhere?: boolean;
      multiLogin?: boolean;
      redirect?: string;
      reason?: LogoutReason;
    } = {}): Location => ({
      name: routes.logout.name,
      // @ts-ignore
      query: {
        ...(logoutEverywhere && { logoutEverywhere }),
        ...(multiLogin && { multiLogin }),
        ...(redirect && { redirect }),
        ...((reason && { r: reason }) as Object),
      },
    }),
    meta: {
      layout: Layout.Empty,
      allowsGuest: true,
    },
  },
  mobileMoreMenu: {
    name: 'mobileMoreMenu',
    path: '/more-menu',
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/views/MobileMoreMenu.vue'),
    route: () => ({ name: routes.mobileMoreMenu.name }),
  },
  token: {
    name: 'token',
    path: '/token/:token',
    meta: {
      layout: Layout.Empty,
      allowsGuest: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "confirmEmployee" */ '@/views/auth/HandleToken.vue'
      ),
    props: (route: Route) => ({
      token: route.params.token,
    }),
  },
  dashboardV2: {
    name: 'dashboardV2',
    path: '/dashboard',
    meta: {
      contentLayout: Layout.EmptyPageLayout,
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboardV2" */ '@/views/dashboardV2/DashboardV2.vue'
      ),
    route({
      changeOfPlan,
      billingPlanId,
      currentPlanId,
    }: {
      changeOfPlan?: boolean;
      billingPlanId?: number;
      currentPlanId?: number;
      redirect?: string;
    } = {}): Location {
      return {
        name: routes.dashboardV2.name,
        params: {
          // @ts-ignore-next-line
          changeOfPlan,
          // @ts-ignore-next-line
          billingPlanId,
          // @ts-ignore-next-line
          currentPlanId,
        },
      };
    },
  },
  referAFriend: {
    name: 'referAFriend',
    path: '/refer-a-friend',
    component: () =>
      import(
        /* webpackChunkName: "referAFriend" */ '@/views/invite/ReferAFriend.vue'
      ),
    route: () => ({ name: routes.referAFriend.name }),
  },
  updateAccountDetails: {
    name: 'updateAccountDetails',
    path: '/update-account-details',
    component: () =>
      import(
        /* webpackChunkName: "updateAccountDetails" */ '../views/auth/AccountDetails.vue'
      ),
    route: () => ({ name: routes.updateAccountDetails.name }),
  },
  updatePassword: {
    name: 'updatePassword',
    path: '/update-password',
    component: () =>
      import(
        /* webpackChunkName: "updatePassword" */ '../views/auth/UpdatePassword.vue'
      ),
    route: () => ({ name: routes.updatePassword.name }),
  },
  clockingProfile: {
    name: 'clockingProfile',
    path: '/clocking-profile',
    component: () =>
      import(
        /* webpackChunkName: "updatePassword" */ '../views/profile/ClockingProfile.vue'
      ),
    route: () => ({ name: routes.clockingProfile.name }),
  },
  verifyPhoneNumber: {
    name: 'verifyPhoneNumber',
    path: '/verify-phone-number',
    component: () =>
      import(
        /* webpackChunkName: "verifyPhoneNumber" */ '../views/auth/VerifyPhoneNumber.vue'
      ),
    route: () => ({ name: routes.verifyPhoneNumber.name }),
  },
  signUp: {
    name: 'signUp',
    path: '/sign-up',
    component: () =>
      import(/* webpackChunkName: "signUp" */ '@/views/sign-up/SignUp.vue'),
    route: ({
      id: planId,
      referralCode,
    }: SignUpPageRouteQueryType = {}): Location => {
      const query: { [key: string]: string } = {};
      if (planId) {
        query.id = String(planId);
      }
      if (referralCode) {
        query.referralCode = referralCode;
      }
      return {
        name: routes.signUp.name,
        query,
      };
    },
    meta: {
      layout: Layout.Empty,
      allowsGuest: true,
    },
  },
  createPasswordReset: {
    name: 'createPasswordReset',
    path: '/reset-password',
    meta: {
      layout: Layout.Empty,
      allowsGuest: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "createPasswordReset" */ '@/views/auth/PasswordResetRequest.vue'
      ),
    route: () => ({ name: routes.createPasswordReset.name }),
  },
  requests: {
    name: 'requests',
    path: '/requests',
    component: () =>
      import(
        /* webpackChunkName: "requests" */ '@/views/requests/Requests.vue'
      ),
    route: (
      tab: RequestTabEnum,
      { entity = null, id = null }: { entity?: EntityName; id?: number } = {},
    ): Location => ({
      name: routes.requests.name,
      query: {
        tab,
        ...(entity && id && { entity: String(entity), id: String(id) }),
      },
    }),
    props: (route: Route) => ({
      propTab: route.query.tab,
    }),
  },
  availableOpenShifts: {
    name: 'availableOpenShifts',
    path: '/available-open-shifts',
    component: () =>
      import(
        /* webpackChunkName: "availableOpenShifts" */ '@/views/schedule/tools/AvailableOpenShifts.vue'
      ),
    route: (): Location => ({ name: routes.availableOpenShifts.name }),
  },
  schedule: {
    name: 'schedule',
    path: '/schedule',
    component: () =>
      import(
        /* webpackChunkName: "schedule" */ '@/views/schedule/Schedule.vue'
      ),
    route: ({
      date,
      view,
      tab,
    }: { date?: string; view?: string; tab?: string } = {}) => ({
      name: routes.schedule.name,
      query: { date, view, tab },
    }),
    props: (route: Route) => ({
      propDate: route.query.date,
      propView: route.query.view,
      propTab: route.query.tab,
    }),
    meta: {
      featureName: Feature.Scheduler,
      contentLayout: Layout.EmptyPageLayout,
    },
  },
  viewShift: {
    childOf: () => routes.schedule.name,
    name: 'viewShift',
    path: '/schedule/view-shift/:id',
    component: () =>
      import(
        /* webpackChunkName: "viewShift" */ '@/views/schedule/dialogs/ViewShiftV2.vue'
      ),
    // In order for the Schedule parent to work we need the same query
    route: (
      { id }: { id: number },
      { date, view, tab }: { date?: string; view?: string; tab?: string } = {},
    ): Location => ({
      name: routes.viewShift.name,
      params: { id: String(id) },
      query: { date, view, tab },
    }),
    meta: {
      featureName: Feature.Scheduler,
    },
  },
  assignShift: {
    childOf: () => routes.schedule.name,
    name: 'assignShift',
    path: '/schedule/assign-shift/:id',
    component: () =>
      import(
        /* webpackChunkName: "assignShift" */ '@/views/schedule/dialogs/AssignShiftV2.vue'
      ),
    // The ID parameter is handled in the parent ScheduleV2 component, which deals with setting appropriate date, etc
    // The other query parameters are not required if calling this directly, but they allow ScheduleV2 to
    // redirect to a version of this URL that has them in, which allows the schedule beneath the dialog to show
    // the appropriate period of time.
    route: (
      { id }: { id: number },
      { date, view, tab }: { date?: string; view?: string; tab?: string } = {},
    ): Location => ({
      name: routes.assignShift.name,
      params: { id: String(id) },
      query: { date, view, tab },
    }),
  },
  reassignShift: {
    childOf: () => routes.schedule.name,
    name: 'reassignShift',
    path: '/schedule/reassign-shift/:id',
    component: () =>
      import(
        /* webpackChunkName: "reassignShift" */ '@/views/schedule/dialogs/AssignShiftV2.vue'
      ),
    // In order for the Schedule parent to work we need the same query
    route: (
      { id }: { id: number },
      { date, view, tab }: { date?: string; view?: string; tab?: string } = {},
    ) => ({
      name: routes.reassignShift.name,
      params: { id },
      query: { date, view, tab },
    }),
    meta: {
      featureName: Feature.Scheduler,
    },
  },
  timesheets: {
    name: 'timesheets',
    path: '/timesheets',
    component: () =>
      import(
        /* webpackChunkName: "timesheet" */ '@/views/timesheetsV2/TimesheetV2.vue'
      ),
    route: ({
      date,
      employee,
    }: {
      date?: string;
      employee?: string;
    } = {}): Location => ({
      name: routes.timesheets.name,
      query: { date, employee },
    }),
    props: (route: Route) => ({
      date: route.query.date,
      employeeId: getNullableNumber(route.query.employee),
    }),
    meta: {
      featureName: Feature.Timesheets,
      contentLayout: Layout.EmptyPageLayout,
      mobileLayoutProps: {
        noPadding: true,
      },
    },
  },

  team: {
    name: 'team',
    path: '/team',
    component: () =>
      import(/* webpackChunkName: "team" */ '@/views/team/Team.vue'),
    route: ({ view }: { view?: string } = {}): Location => ({
      name: routes.team.name,
      query: { view },
    }),
    props: (route: Route) => ({
      propDate: route.query.date,
      propView: route.query.view,
    }),
  },
  profile: {
    name: 'profile',
    path: '/profile/:id',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/profile/Profile.vue'),
    props: (route) => ({ employeeId: Number(route.params.id) }),
    meta: {
      contentLayout: Layout.EmptyPageLayout,
      sidebarCollapsed: false,
    },
  },
  profileSummary: {
    childOf: () => routes.profile.name,
    name: 'profileSummary',
    path: '/profile/:id',
    component: () =>
      import(
        /* webpackChunkName: "profileSummary" */ '@/views/profile/ProfileSummary.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.profileSummary.name, params: { id: employeeId } }),
  },
  myDocuments: {
    childOf: () => routes.profile.name,
    name: 'myDocuments',
    path: '/profile/:id/documents',
    component: () =>
      import(
        /* webpackChunkName: "myDocuments" */ '@/views/documents/MyDocuments.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.myDocuments.name, params: { id: employeeId } }),
  },
  unavailability: {
    childOf: () => routes.profile.name,
    name: 'unavailability',
    path: '/profile/:id/unavailability',
    component: () =>
      import(
        /* webpackChunkName: "unavailability" */ '@/views/unavailability/Unavailability.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.unavailability.name, params: { id: employeeId } }),
    props: (route) => ({ employeeId: route.params.id }),
    meta: {
      featureName: Feature.Availability,
    },
  },
  leave: {
    childOf: () => routes.profile.name,
    name: 'leave',
    path: '/profile/:id/leave',
    component: () =>
      import(/* webpackChunkName: "leave" */ '@/views/leave/Leave.vue'),
    route: (employeeId: number, tab?: LeaveTab, date?: string): Location => ({
      name: routes.leave.name,
      // @ts-ignore
      params: { id: employeeId },
      query: { tab, date },
    }),
    props: (route: Route) => ({
      propTab: route.query.tab,
      propDate: route.query.date,
    }),
    meta: {
      featureName: Feature.Leave,
    },
  },
  absence: {
    childOf: () => routes.profile.name,
    name: 'absence',
    path: '/profile/:id/absence',
    component: () =>
      import(/* webpackChunkName: "absence" */ '@/views/absence/Absence.vue'),
    route: (employeeId: number): Location => ({
      name: routes.absence.name,
      // @ts-ignore
      params: { id: employeeId },
    }),
    meta: {
      featureName: Feature.Absence,
    },
  },
  employmentDetails: {
    childOf: () => routes.profile.name,
    name: 'employmentDetails',
    path: '/profile/:id/employment-details',
    component: () =>
      import(
        /* webpackChunkName: "employmentDetails" */ '@/views/profile/EmploymentDetails.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.employmentDetails.name, params: { id: employeeId } }),
    props: (route: Route) => ({ employeeId: Number(route.params.id) }),
  },
  permissions: {
    childOf: () => routes.profile.name,
    name: 'permissions',
    path: '/profile/:id/permissions',
    component: () =>
      import(
        /* webpackChunkName: "permissions" */ '@/views/profile/Permissions.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.permissions.name, params: { id: employeeId } }),
    props: (route: Route) => ({ employeeId: Number(route.params.id) }),
  },
  whereAndWhen: {
    childOf: () => routes.profile.name,
    name: 'whereAndWhen',
    path: '/profile/:id/where-and-when',
    component: () =>
      import(
        /* webpackChunkName: "whereAndWhen" */ '@/views/profile/WhereAndWhen.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.whereAndWhen.name, params: { id: employeeId } }),
  },
  personalDetails: {
    childOf: () => routes.profile.name,
    name: 'personalDetails',
    path: '/profile/:id/personal-details',
    component: () =>
      import(
        /* webpackChunkName: "personalDetails" */ '@/views/profile/PersonalDetails.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.personalDetails.name, params: { id: employeeId } }),
  },
  wageAndSalary: {
    childOf: () => routes.profile.name,
    name: 'wageAndSalary',
    path: '/profile/:id/wage-and-salary',
    component: () =>
      import(
        /* webpackChunkName: "wageAndSalary" */ '@/views/profile/WageAndSalary.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.wageAndSalary.name, params: { id: employeeId } }),
    meta: {
      featureName: Feature.HrManagement,
    },
  },
  emergencyDetails: {
    childOf: () => routes.profile.name,
    name: 'emergencyDetails',
    path: '/profile/:id/emergency-details',
    component: () =>
      import(
        /* webpackChunkName: "emergencyDetails" */ '@/views/profile/EmergencyDetails.vue'
      ),
    route: (employeeId: number): Location =>
      // @ts-ignore
      ({ name: routes.emergencyDetails.name, params: { id: employeeId } }),
  },
  notificationPreferences: {
    name: 'notificationPreferences',
    path: '/notification-preferences',
    component: () =>
      import(
        /* webpackChunkName: "notificationPreferences" */ '@/views/profile/NotificationPreferences.vue'
      ),
    route: (): Location =>
      // @ts-ignore
      ({ name: routes.notificationPreferences.name }),
  },
  timeClock: {
    name: 'timeClock',
    path: '/time-clock',
    component: () =>
      import(
        /* webpackChunkName: "time-clock" */ '@/views/time-clock/TimeClock.vue'
      ),
    route: (): Location => ({ name: routes.timeClock.name }),
    meta: {
      featureName: Feature.ClockInOut,
      contentLayout: Layout.EmptyPageLayout,
    },
  },
  /* Reports */
  // Unrouted to, only serves as a parent route for other report pages
  reports: {
    name: 'reports',
    path: '/reports-parent',
    component: () =>
      import(
        /* webpackChunkName: "reportsParent" */ '@/layouts/EmptyRouter.vue'
      ),
    meta: {
      sidebarCollapsed: false,
      contentLayout: Layout.Reports,
      fixedContentWidth: false,
    },
  },
  reportsMenu: {
    name: 'reportsMenu',
    path: '/reports',
    component: () =>
      import(
        /* webpackChunkName: "reportsMenu" */ '@/components/menu/ReportsMenu.vue'
      ),
    route: () => ({ name: routes.reportsMenu.name }),
  },
  // Shift Cost section
  shiftDailyTotals: {
    childOf: () => routes.reports.name,
    name: 'shiftDailyTotals',
    path: '/reports/shift-daily-totals',
    component: () =>
      import(
        /* webpackChunkName: "shiftDailyTotals" */ '@/views/reports/hours-and-costs/ShiftDailyTotals.vue'
      ),
    route: () => ({ name: routes.shiftDailyTotals.name }),
  },
  shiftTotalsByEmployee: {
    childOf: () => routes.reports.name,
    name: 'shiftTotalsByEmployee',
    path: '/reports/shift-totals-by-employee',
    component: () =>
      import(
        /* webpackChunkName: "shiftTotalsByEmployee" */ '@/views/reports/hours-and-costs/ShiftTotalsByEmployee.vue'
      ),
    route: () => ({ name: routes.shiftTotalsByEmployee.name }),
  },
  shiftTotalsByJobRole: {
    childOf: () => routes.reports.name,
    name: 'shiftTotalsByJobRole',
    path: '/reports/shift-totals-by-job-role',
    component: () =>
      import(
        /* webpackChunkName: "shiftTotalsByJobRole" */ '@/views/reports/hours-and-costs/ShiftTotalsByJobRole.vue'
      ),
    route: () => ({ name: routes.shiftTotalsByJobRole.name }),
  },
  shiftTotalsByLocation: {
    childOf: () => routes.reports.name,
    name: 'shiftTotalsByLocation',
    path: '/reports/shift-totals-by-location',
    component: () =>
      import(
        /* webpackChunkName: "shiftTotalsByLocation" */ '@/views/reports/hours-and-costs/ShiftTotalsByLocation.vue'
      ),
    route: () => ({ name: routes.shiftTotalsByLocation.name }),
  },
  shiftTotalsByGroup: {
    childOf: () => routes.reports.name,
    name: 'shiftTotalsByGroup',
    path: '/reports/shift-totals-by-group',
    component: () =>
      import(
        /* webpackChunkName: "shiftTotalsByGroup" */ '@/views/reports/hours-and-costs/ShiftTotalsByGroup.vue'
      ),
    route: () => ({ name: routes.shiftTotalsByGroup.name }),
  },
  shiftTotalsByEmploymentType: {
    childOf: () => routes.reports.name,
    name: 'shiftTotalsByEmploymentType',
    path: '/reports/shift-totals-by-employment-type',
    component: () =>
      import(
        /* webpackChunkName: "shiftTotalsByEmploymentType" */ '@/views/reports/hours-and-costs/ShiftTotalsByEmploymentType.vue'
      ),
    route: () => ({ name: routes.shiftTotalsByEmploymentType.name }),
  },
  // Logs section
  messageHistory: {
    childOf: () => routes.reports.name,
    name: 'messageHistory',
    path: '/reports/message-history',
    component: () =>
      import(
        /* webpackChunkName: "messageHistory" */ '@/views/reports/message-history/MessageHistory.vue'
      ),
    route: () => ({ name: routes.messageHistory.name }),
  },
  /* Reports */
  taskManager: {
    name: 'taskManager',
    path: '/task-manager',
    component: () =>
      import(
        /* webpackChunkName: "taskManager" */ '@/views/task-manager/TaskManager.vue'
      ),
    route: (): Location => ({ name: routes.taskManager.name }),
  },
  social: {
    name: 'social',
    path: '/social',
    component: () =>
      import(/* webpackChunkName: "social" */ '@/views/social/Social.vue'),
    route: (): Location => ({ name: routes.social.name }),
  },
  // Unrouted to, only serves as a parent route for other settings pages
  settings: {
    name: 'settings',
    path: '/settings-parent',
    component: () =>
      import(
        /* webpackChunkName: "settingsParent" */ '@/layouts/EmptyRouter.vue'
      ),
    meta: {
      contentLayout: Layout.Settings,
      sidebarCollapsed: false,
    },
  },
  settingsMenu: {
    name: 'settingsMenu',
    path: '/settings',
    component: () =>
      import(
        /* webpackChunkName: "settingsMenu" */ '@/views/settings/SettingsMenu.vue'
      ),
    route: () => ({ name: routes.settingsMenu.name }),
  },
  generalSettings: {
    childOf: () => routes.settings.name,
    name: 'generalSettings',
    path: '/settings/general',
    component: () =>
      import(
        /* webpackChunkName: "generalSettings" */ '@/views/settings/GeneralSettings.vue'
      ),
    route: () => ({ name: routes.generalSettings.name }),
  },
  manageWorkPatterns: {
    childOf: () => routes.settings.name,
    name: 'manageWorkPatterns',
    path: '/settings/work-patterns',
    component: () =>
      import(
        /* webpackChunkName: "manageWorkPatterns" */ '@/views/settings/ManageWorkPatterns.vue'
      ),
    route: () => ({ name: routes.manageWorkPatterns.name }),
  },
  notificationSettings: {
    childOf: () => routes.settings.name,
    name: 'notificationSettings',
    path: '/settings/notifications',
    component: () =>
      import(
        /* webpackChunkName: "notificationSettings" */ '@/views/settings/NotificationSettings.vue'
      ),
    route: () => ({ name: routes.notificationSettings.name }),
  },
  scheduleSettings: {
    childOf: () => routes.settings.name,
    name: 'scheduleSettings',
    path: '/settings/schedule',
    component: () =>
      import(
        /* webpackChunkName: "scheduleSettings" */ '@/views/settings/ScheduleSettings.vue'
      ),
    route: () => ({ name: routes.scheduleSettings.name }),
  },
  manageSchedules: {
    childOf: () => routes.settings.name,
    name: 'manageSchedules',
    path: '/settings/schedules',
    component: () =>
      import(
        /* webpackChunkName: "manageSchedules" */ '@/views/settings/ManageSchedules.vue'
      ),
    route: () => ({ name: routes.manageSchedules.name }),
  },
  manageShiftTemplates: {
    childOf: () => routes.settings.name,
    name: 'manageShiftTemplates',
    path: '/settings/shift-templates',
    component: () =>
      import(
        /* webpackChunkName: "manageShiftTemplates" */ '@/views/settings/ManageShiftTemplates.vue'
      ),
    route: () => ({ name: routes.manageShiftTemplates.name }),
  },
  manageShiftAreasSettings: {
    childOf: () => routes.settings.name,
    name: 'manageShiftAreasSettings',
    path: '/settings/shift-areas',
    redirect: () => ({ name: routes.manageShiftAreas.name }),
    component: () =>
      import(
        /* webpackChunkName: "manageShiftAreasSettings" */ '@/layouts/EmptyRouter.vue'
      ),
    route: () => ({ name: routes.manageShiftAreasSettings.name }),
    meta: {
      featureName: Feature.ShiftAreas,
    },
  },
  manageShiftAreas: {
    childOf: () => routes.manageShiftAreasSettings.name,
    name: 'manageShiftAreas',
    path: '/settings/shift-areas',
    component: () =>
      import(
        /* webpackChunkName: "manageShiftAreas" */ '@/views/settings/shift-area/ManageShiftAreas.vue'
      ),
    route: () => ({ name: routes.manageShiftAreas.name }),
    meta: {
      featureName: Feature.ShiftAreas,
    },
  },
  createShiftArea: {
    childOf: () => routes.manageShiftAreasSettings.name,
    name: 'createShiftArea',
    path: '/settings/shift-areas/create',
    component: () =>
      import(
        /* webpackChunkName: "createShiftArea" */ '@/views/settings/shift-area/components/CreateEditShiftArea.vue'
      ),
    route: () => ({ name: routes.createShiftArea.name }),
    meta: {
      featureName: Feature.ShiftAreas,
    },
  },
  editShiftArea: {
    childOf: () => routes.manageShiftAreasSettings.name,
    name: 'editShiftArea',
    path: '/settings/shift-areas/:id',
    component: () =>
      import(
        /* webpackChunkName: "editShiftArea" */ '@/views/settings/shift-area/components/CreateEditShiftArea.vue'
      ),
    props: (route) => ({
      shiftAreaId: Number(route.params.id),
    }),
    route: (shiftAreaId: number): Location => ({
      name: routes.editShiftArea.name,
      params: { id: `${shiftAreaId}` },
    }),
    meta: {
      featureName: Feature.ShiftAreas,
    },
  },
  breakSettings: {
    childOf: () => routes.settings.name,
    name: 'breakSettings',
    path: '/settings/breaks',
    component: () =>
      import(
        /* webpackChunkName: "breakSettings" */ '@/views/settings/BreakSettings.vue'
      ),
    route: () => ({ name: routes.breakSettings.name }),
  },
  locationSettings: {
    childOf: () => routes.settings.name,
    name: 'locationSettings',
    path: '/settings/locations',
    redirect: () => ({ name: routes.manageLocations.name }),
    component: () =>
      import(
        /* webpackChunkName: "locationSettings" */ '@/layouts/EmptyRouter.vue'
      ),
    route: () => ({ name: routes.locationSettings.name }),
  },
  manageLocations: {
    childOf: () => routes.locationSettings.name,
    name: 'manageLocations',
    path: '/settings/locations',
    component: () =>
      import(
        /* webpackChunkName: "manageLocations" */ '@/views/settings/locations/ManageLocations.vue'
      ),
    route: () => ({ name: routes.manageLocations.name }),
  },
  editLocation: {
    childOf: () => routes.locationSettings.name,
    name: 'editLocation',
    path: '/settings/locations/:id',
    component: () =>
      import(
        /* webpackChunkName: "editLocation" */ '@/views/settings/locations/EditLocation.vue'
      ),
    props: (route) => ({
      locationId: Number(route.params.id),
    }),
    route: (locationId: number): Location => ({
      name: routes.editLocation.name,
      params: { id: `${locationId}` },
    }),
  },
  manageScheduleEvents: {
    childOf: () => routes.settings.name,
    name: 'manageScheduleEvents',
    path: '/settings/schedule-events',
    component: () =>
      import(
        /* webpackChunkName: "manageScheduleEvents" */ '@/views/settings/ManageScheduleEvents.vue'
      ),
    route: () => ({ name: routes.manageScheduleEvents.name }),
  },
  manageTags: {
    childOf: () => routes.settings.name,
    name: 'manageTags',
    path: '/settings/tags',
    component: () =>
      import(
        /* webpackChunkName: "manageTags" */ '@/views/settings/ManageTags.vue'
      ),
    route: () => ({ name: routes.manageTags.name }),
  },
  manageJobRoles: {
    childOf: () => routes.settings.name,
    name: 'manageJobRoles',
    path: '/settings/job-roles',
    component: () =>
      import(
        /* webpackChunkName: "manageJobRoles" */ '@/views/settings/ManageJobRoles.vue'
      ),
    route: () => ({ name: routes.manageJobRoles.name }),
  },
  manageEmployeeGroups: {
    childOf: () => routes.settings.name,
    name: 'manageEmployeeGroups',
    path: '/settings/employee-groups',
    component: () =>
      import(
        /* webpackChunkName: "manageEmployeeGroups" */ '@/views/settings/ManageEmployeeGroups.vue'
      ),
    route: () => ({ name: routes.manageEmployeeGroups.name }),
  },
  manageEmployeeAttributes: {
    childOf: () => routes.settings.name,
    name: 'manageEmployeeAttributes',
    path: '/settings/employee-attributes',
    component: () =>
      import(
        /* webpackChunkName: "ManageEmployeeAttributes" */ '@/views/settings/ManageEmployeeAttributes.vue'
      ),
    route: () => ({ name: routes.manageEmployeeAttributes.name }),
  },
  leaveSettings: {
    childOf: () => routes.settings.name,
    name: 'leaveSettings',
    path: '/settings/leave-settings',
    component: () =>
      import(
        /* webpackChunkName: "leaveSettings" */ '@/views/settings/time-off/LeaveSettings.vue'
      ),
    route: () => ({ name: routes.leaveSettings.name }),
    meta: {
      featureName: Feature.Leave,
    },
  },
  leaveTypeSettings: {
    childOf: () => routes.settings.name,
    name: 'leaveTypeSettings',
    path: '/settings/leave-types',
    redirect: () => ({ name: routes.leaveTypes.name }),
    component: () =>
      import(
        /* webpackChunkName: "leaveTypeSettings" */ '@/layouts/EmptyRouter.vue'
      ),
    route: () => ({ name: routes.leaveTypeSettings.name }),
  },
  leaveTypes: {
    childOf: () => routes.leaveTypeSettings.name,
    name: 'leaveTypes',
    path: '/settings/leave-types',
    component: () =>
      import(
        /* webpackChunkName: "leaveTypes" */ '@/views/settings/time-off/LeaveTypes.vue'
      ),
    route: () => ({ name: routes.leaveTypes.name }),
  },
  createLeaveType: {
    childOf: () => routes.leaveTypeSettings.name,
    name: 'createLeaveType',
    path: '/settings/leave-types/create',
    component: () =>
      import(
        /* webpackChunkName: "createLeaveType" */ '@/views/settings/time-off/components/CreateEditLeaveType.vue'
      ),
    route: () => ({ name: routes.createLeaveType.name }),
  },
  editLeaveType: {
    childOf: () => routes.leaveTypeSettings.name,
    name: 'editLeaveType',
    path: '/settings/leave-types/:id',
    component: () =>
      import(
        /* webpackChunkName: "editLeaveType" */ '@/views/settings/time-off/components/CreateEditLeaveType.vue'
      ),
    props: (route) => ({
      leaveTypeId: Number(route.params.id),
    }),
    route: (leaveTypeId: number): Location => ({
      name: routes.editLeaveType.name,
      params: { id: `${leaveTypeId}` },
    }),
  },
  leavePolicySettings: {
    childOf: () => routes.settings.name,
    name: 'leavePolicySettings',
    path: '/settings/leave-policies',
    redirect: () => ({ name: routes.leavePolicies.name }),
    component: () =>
      import(
        /* webpackChunkName: "leavePolicySettings" */ '@/layouts/EmptyRouter.vue'
      ),
    route: () => ({ name: routes.leavePolicySettings.name }),
  },
  leavePolicies: {
    childOf: () => routes.leavePolicySettings.name,
    name: 'leavePolicies',
    path: '/settings/leave-policies',
    component: () =>
      import(
        /* webpackChunkName: "leavePolicies" */ '@/views/settings/time-off/ManageLeavePolicies.vue'
      ),
    route: () => ({ name: routes.leavePolicies.name }),
    meta: {
      featureName: Feature.Leave,
    },
  },
  createLeavePolicy: {
    childOf: () => routes.leavePolicySettings.name,
    name: 'createLeavePolicy',
    path: '/settings/leave-policies/create',
    component: () =>
      import(
        /* webpackChunkName: "createLeavePolicy" */ '@/views/settings/time-off/components/LeavePolicyForm.vue'
      ),
    route: (): Location => ({
      name: routes.createLeavePolicy.name,
    }),
  },
  editLeavePolicy: {
    childOf: () => routes.leavePolicySettings.name,
    name: 'editLeavePolicy',
    path: '/settings/leave-policies/:id',
    component: () =>
      import(
        /* webpackChunkName: "editLeavePolicy" */ '@/views/settings/time-off/components/LeavePolicyForm.vue'
      ),
    props: (route) => ({
      leavePolicyId: Number(route.params.id),
    }),
    route: (leavePolicyId: number): Location => ({
      name: routes.editLeavePolicy.name,
      params: { id: `${leavePolicyId}` },
    }),
  },
  timesheetSettings: {
    childOf: () => routes.settings.name,
    name: 'timesheetSettings',
    path: '/settings/timesheets',
    component: () =>
      import(
        /* webpackChunkName: "timesheetSettings" */ '@/views/settings/TimesheetSettings.vue'
      ),
    route: () => ({ name: routes.timesheetSettings.name }),
    meta: {
      featureName: Feature.ClockInOut,
    },
  },
  accountSettings: {
    childOf: () => routes.settings.name,
    name: 'accountSettings',
    path: '/settings/account',
    component: () =>
      import(
        /* webpackChunkName: "accountSettings" */ '@/views/settings/AccountSettings.vue'
      ),
    route: (): Location =>
      // @ts-ignore
      ({ name: routes.accountSettings.name }),
  },
  billingSettings: {
    childOf: () => routes.settings.name,
    name: 'billingSettings',
    path: '/settings/billing',
    component: () =>
      import(
        /* webpackChunkName: "billingSettings" */ '@/views/settings/BillingSettings.vue'
      ),
    route: (): Location =>
      // @ts-ignore
      ({ name: routes.billingSettings.name }),
    props: (route: Route) => ({
      updateBilling: route.params.updateBilling || false,
    }),
  },
  offers: {
    childOf: () => routes.settings.name,
    name: 'offers',
    path: '/settings/offers',
    component: () =>
      import(
        /* webpackChunkName: "manageOffers" */ '@/views/settings/Offers.vue'
      ),
    route: () => ({ name: routes.offers.name }),
  },
  redeemOffer: {
    childOf: () => routes.offers.name,
    name: 'redeemOffer',
    path: '/settings/offers/redeem-offer',
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/modals/RedeemOffer.vue'
      ),
  },
  invoices: {
    childOf: () => routes.settings.name,
    name: 'invoices',
    path: '/settings/invoices',
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ '@/views/settings/Invoices.vue'
      ),
    route: () => ({ name: routes.invoices.name }),
  },
  digiticketsSettings: {
    childOf: () => routes.settings.name,
    name: 'digiticketsSettings',
    path: '/settings/integrations/digitickets',
    component: () =>
      import(
        /* webpackChunkName: "digiticketsSettings" */ '@/views/settings/DigiticketsSettings.vue'
      ),
    route: () => ({ name: routes.digiticketsSettings.name }),
  },
  trekksoftSettings: {
    childOf: () => routes.settings.name,
    name: 'trekksoftSettings',
    path: '/settings/integrations/trekksoft',
    // eslint-disable-next-line
    component: () =>
      import(
        /* webpackChunkName: "trekksoftSettings" */ '@/views/settings/TrekksoftSettings.vue'
      ),
    route: () => ({ name: routes.trekksoftSettings.name }),
  },
  manageClockInPortals: {
    childOf: () => routes.settings.name,
    name: 'manageClockInPortals',
    path: '/settings/clock-in-portals',
    component: () =>
      import(
        /* webpackChunkName: "manageClockInPortals" */ '@/views/settings/ManageClockInPortals.vue'
      ),
    route: () => ({ name: routes.manageClockInPortals.name }),
  },
  developerSettings: {
    name: 'developerSettings',
    path: '/developers',
    component: () =>
      import(
        /* webpackChunkName: "developerSettings" */ '@/views/settings/DeveloperSettings.vue'
      ),
    route: () => ({ name: routes.developerSettings.name }),
  },
  pageNotFound: {
    // Catch-all route for 404
    name: 'pageNotFound',
    path: '*',
    component: () =>
      import(/* webpackChunkName: "pageNotFound" */ '@/views/PageNotFound.vue'),
    route: () => ({ name: routes.pageNotFound.name }),
    meta: {
      layout: Layout.Empty,
      allowsGuest: true,
    },
  },
});

// Make sure developers have correctly set the name property for each route - it should match the route's key
// In the long term, this could be moved to a build or lint script to avoid it running on page load
Object.keys(routes).forEach((routeName) => {
  if (routes[routeName].name !== routeName) {
    throw Error(
      `routes.${routeName}.name is "${routes[routeName].name}" and should be "${routeName}"`,
    );
  }
});
